import {Component, HostListener, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute, Router} from "@angular/router";
import {UserInfoService} from "../user-info.service";
import { AuthenticationService } from '../authentication.service';
import { UserInterface } from '../../shared/interfaces/user.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'up-subscription-expired',
  templateUrl: './subscription-expired.component.html',
  styleUrls: ['./subscription-expired.component.scss']
})
export class SubscriptionExpiredComponent implements OnInit {
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  public userName: string = ''

  @HostListener('window:resize', ['$event'])
  windowResize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private userInfoService: UserInfoService
  ) {
    this.setUserName(this.userInfoService.user);
  }

  private setUserName(user: UserInterface) {
    if (user !== void 0 && user !== null) {
      this.userName = `${user?.firstName} ${user?.lastName}`.trim();
    }
  }

  public ngOnInit(): void {
    this.setUserName(this.userInfoService.user);

  }
}
