import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'up-firebase-actions',
  templateUrl: './firebase-actions.component.html',
  styleUrls: ['./firebase-actions.component.scss']
})
export class FirebaseActionsComponent implements OnInit, OnDestroy {

  $destroy = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnDestroy() {
    this.$destroy.next();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.$destroy))
      .subscribe(params => {

      switch (params.mode) {
        case 'resetPassword':
          // Display reset password handler and UI.
          let url = `/pass-reset?oobCode=${params.oobCode}`;
          if (params.workerSetup) {
            url = `${url}&workerSetup=true`;
          }
          this.router.navigateByUrl(url);
          break;
        case 'recoverEmail':
          // Display email recovery handler and UI.
          this.router.navigateByUrl('/login');
          break;
        case 'verifyEmail':
          // Display email verification handler and UI.
          this.router.navigateByUrl(`/verify-email?oobCode=${params.oobCode}`);
          break;
        default:
        // Error: invalid mode.
          this.router.navigateByUrl('/login');
      }

    });
  }

}
