import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FREE_USERS_LIMIT, PLAN_PRICES, USER_PRICE} from '../../../../shared/constants/company-plan';
import {SidePopoverScreenComponent} from '../../../../shared/components/side-popover-screen/side-popover-screen.component';
import {UserInfoService} from '../../../../core/user-info.service';

@Component({
  selector: 'up-payment-charge',
  templateUrl: './payment-charge.component.html',
  styleUrls: ['./payment-charge.component.scss']
})
export class PaymentChargeComponent implements OnInit, OnChanges {

  @Input() companyPlan = 1;
  @Input() companyPlanPeriodYearly = false;
  @Input() isLoading = false;
  @Input() wrapperSidebar: SidePopoverScreenComponent;
  @Output() onSubmit = new EventEmitter();
  usersCount = 8;
  signedUpUsers = 1;
  maxUsersCount = 10;
  includedInPlanUsers = 8;
  userPrice = 0;
  currentPlanPrice = 0;
  showConfirmationScreen = false;
  usersAdditional = 0;
  currencySymbol: string;

  constructor(
    private userInfoService: UserInfoService,
    private translateService: TranslateService
  ) {
    userInfoService.companyChanged.subscribe(company => {
      if (company && company.id) {
        this.usersCount = company.plan.userQty + company.additionalUsers;
        this.usersAdditional = company.additionalUsers;
        this.currencySymbol = company.currencySymbol;
        this.signedUpUsers = company.users;
      }
    });
  }



  ngOnInit(): void {
    this.includedInPlanUsers = FREE_USERS_LIMIT[this.companyPlan];
    this.currentPlanPrice = PLAN_PRICES[this.companyPlan][this.companyPlanPeriodYearly ? 'yearly' : 'monthly'];
    this.userPrice = USER_PRICE[this.companyPlan];
    this.usersCount = this.includedInPlanUsers + this.usersAdditional;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading && !changes.isLoading.firstChange) {
      if (changes.isLoading.currentValue === false && changes.isLoading.previousValue === true) {
        this.wrapperSidebar.closeModal();
      }
    }
  }

  get totalPrice() {
    return this.currentPlanPrice + ((this.usersCount - this.includedInPlanUsers) * this.userPriceByPeriod);
  }

  get translatedUserPlan() {
    return this.translateService.instant('other.company-plan.' + this.companyPlan);
  }

  get translatedPeriod() {
    return this.translateService.instant('more-options.' + (this.companyPlanPeriodYearly ? 'year' : 'month'));
  }

  get userPriceByPeriod() {
    const countMonths = this.companyPlanPeriodYearly ? 12 : 1;
    return countMonths * this.userPrice;
  }
}
