import {Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'up-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  isLoading = false;
  emailVerified = false;

  screenHeight = window.innerHeight;
  actionCode = null;

  errorMessage = '';

  @HostListener('window:resize', ['$event'])
  windowResize() {
    this.screenHeight = window.innerHeight;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.actionCode = params.oobCode;

      if (this.actionCode) {
        this.isLoading = true;
        this.authenticationService.applyActionCode(this.actionCode)
          .then((result) => {
            this.isLoading = false;
            this.emailVerified = true;
          }).catch(error => {
            this.isLoading = false;
            this.errorMessage = this.translateService.instant('verify-email-form.invalid-code');
          });
      }
    });
  }

  openPanel() {
    this.router.navigateByUrl('/inventory/Main')
      .then(() => window.location.reload());
  }


}
