import {Injectable} from '@angular/core';
import {Observable, Subject, fromEvent} from 'rxjs';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public onRootNavigation = new Subject();
  public editingForm: FormGroup;
  public confirm = new Subject();
  public onConfirmationClosed = new Subject<boolean>();
  public networkStatus: boolean = true

  constructor() {
  }

  navigateToRootInventory() {
    this.onRootNavigation.next();
  }

  confirmClosing() {
    this.confirm.next();
  }

  getNetworkStatusObservable(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      observer.next(this.networkStatus);

      fromEvent(window, 'online').subscribe(() => {
        this.networkStatus = true;
        observer.next(true);
      });

      fromEvent(window, 'offline').subscribe(() => {
        this.networkStatus = false;
        observer.next(false);
      });
    });
  }
}
