import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SnackBarService} from '../../../shared/services/snack-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {SidePopoverScreenComponent} from '../side-popover-screen/side-popover-screen.component';

interface QueryParams {
  lang: string;
}

@Component({
  selector: 'up-change-plan',
  templateUrl: './change-plan.component.html',
  styleUrls: ['./change-plan.component.scss']
})
export class ChangePlanComponent implements OnInit, OnChanges {

  @Input() currentPlanId = 1;
  @Input() currentYearlyPeriod = false;
  @Input() isLoading = false;
  @Input() wrapperSidebar: SidePopoverScreenComponent;
  @Output() choosePlan = new EventEmitter<{planId: number, yearly: boolean}>();

  yearlyPeriod = false;

  queryParams: QueryParams;
  prices = {
    free: {
      monthly: 0
    },
    advanced: {
      monthly: 29,
      yearly: 278
    },
    premium: {
      monthly: 69,
      yearly: 662
    }
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private snackBarService: SnackBarService
  ) {
  }

  ngOnInit(): void {
    this.yearlyPeriod = this.currentYearlyPeriod;
    this.route.queryParams.subscribe((params: QueryParams) => {
      this.queryParams = params;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading && !changes.isLoading.firstChange) {
      if (changes.isLoading.currentValue === false && changes.isLoading.previousValue === true) {
        this.wrapperSidebar.closeModal();
      }
    }

    if (changes.currentYearlyPeriod) {
      this.yearlyPeriod = changes.currentYearlyPeriod.currentValue;
    }
  }

  priceFormer(category, monthly): string {
    return `$${(this.prices[category].monthly * (monthly ? 1 : 12)).toFixed(2)}`;
  }

  emitPlan(planId) {
    this.choosePlan.emit({yearly: this.yearlyPeriod, planId});
  }

  get freeButtonPlanText() {
    if (this.currentPlanId === 1) {
      return this.translateService.instant('choose-plan.current-plan');
    }

    return this.translateService.instant('choose-plan.downgrade');
  }

  get advancedButtonPlanText() {
    switch (this.currentPlanId) {
      case 1:
        return this.translateService.instant('choose-plan.upgrade');
      case 2:
        if (this.currentYearlyPeriod !== this.yearlyPeriod && this.yearlyPeriod) {
          return this.translateService.instant('choose-plan.pay-yearly');
        } else if (this.currentYearlyPeriod !== this.yearlyPeriod && !this.yearlyPeriod) {
          return this.translateService.instant('choose-plan.pay-monthly');
        } else {
          return this.translateService.instant('choose-plan.current-plan');
        }
      case 3:
        return this.translateService.instant('choose-plan.downgrade');
      default:
        return this.translateService.instant('choose-plan.upgrade');
    }
  }

  get premiumButtonPlanText() {
    if (this.currentPlanId === 3) {
      if (this.currentYearlyPeriod !== this.yearlyPeriod && this.yearlyPeriod) {
        return this.translateService.instant('choose-plan.pay-yearly');
      } else if (this.currentYearlyPeriod !== this.yearlyPeriod && !this.yearlyPeriod) {
        return this.translateService.instant('choose-plan.pay-monthly');
      } else {
        return this.translateService.instant('choose-plan.current-plan');
      }
    }

    return this.translateService.instant('choose-plan.upgrade');
  }
}
