<div class="wrapper" *ngIf="!loadingUserInfo">

  <div class="side-bar">
    <img class="logo" [src]="logoUrl" alt="logo">
    <div class="test">
      <div class="inner">
        <div class="mega-in"></div>
      </div>
    </div>
    <ng-container >
      <up-core-menu></up-core-menu>
    </ng-container>
  </div>

  <!-- id="global-layout" is used in side-popover-screen.component.ts -->
  <div id="global-content" class="content">
    <router-outlet></router-outlet>
    <up-msg-snack></up-msg-snack>
  </div>


</div>

<div *ngIf="loadingUserInfo" class="full-width d-flex justify-content-center mt-4">
  <div class="up-spinner"></div>
</div>

<div class="modal-container" id="network-modal" *ngIf="!networkStatus">
  <div class="background"></div>
  <div class="modal-window rights-modal">
    <div class="modal-window-content">
      <p>{{ 'other.no-internet-connection' | translate }}</p>
      <div>
        <img src="../../../assets/icons/icon_no_wi_fi.svg" alt="">
        <img src="assets/icons/icon_no_mobile_internet.svg" alt="">
        <img src="assets/icons/icon_no_lan_connection.svg" alt="">
      </div>
      <div>
        <button (click)="checkNetworkConnection()"> {{ 'snackbar-messages.check-connection' | translate }} </button>
      </div>
    </div>
  </div>
</div>