<div class="workers-content">
  <p class="page-title settings-title" style="margin: 41px -50px 120px;">
    {{ ('more-options.' + (companyPlanPeriodYearly ? 'yearly-payment' : 'monthly-payment')) | translate }}
  </p>

  <div class="center-container">
    <div class="cell" style="margin-bottom: 33px;">
      <label>{{ 'settings.workers-number-page.plan' | translate:{
          plan: translatedUserPlan,
          planPrice: currentPlanPrice,
          currency: currencySymbol || '',
          period: translatedPeriod
      } }}</label>
    </div>

    <div class="cell" style="margin-bottom: 130px;">
      <label>
        {{ 'settings.workers-number-page.additional-worker' | translate }} * {{ usersCount - includedInPlanUsers }}
        ---
        ${{ userPriceByPeriod * (usersCount - includedInPlanUsers)}}/{{ translatedPeriod }}
      </label>
    </div>

    <p class="total-price">{{ 'settings.workers-number-page.total' | translate:{
        price: totalPrice,
        currency: currencySymbol || '',
        period: translatedPeriod
    } }}</p>

    <button (click)="onSubmit.emit({usersAdditional: usersAdditional})" class="up-btn up-btn-primary billing-content-btn proceed-btn">
      {{ 'settings.workers-number-page.proceed-btn' | translate}}
    </button>
  </div>
</div>

<ng-container *ngIf="isLoading">
  <div style="position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(0,0,0,0.37);">
  </div>
  <div class="full-width d-flex justify-content-center mt-4">
    <div class="up-spinner-frame"></div>
  </div>
</ng-container>
