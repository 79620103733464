import {Component, EventEmitter, Input, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';


@Component({
  selector: 'contact-us-section',
  templateUrl: './contact-us-section.component.html',
  styleUrls: ['./contact-us-section.component.scss']
})
export class ContactUsSectionComponent {

  @Output() openContactModal = new EventEmitter<boolean>();

  appLink = '';
  constructor() {
    this.appLink = environment.googleAppLink;
  }

  onOpenContactModal() {
    this.openContactModal.emit(true);
  }
}
