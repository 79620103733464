import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CommonService} from '../../shared/services/common.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../authentication.service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {UserPermissionsService} from '../user-permissions.service';
import {NotificationsService} from '../../modules/notifications/notifications.service';
import {UserInfoService} from '../user-info.service';
import {RTCounterInterface} from '../../shared/interfaces/rt-counter.interface';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'up-core-menu',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss']
})
export class CoreMenuComponent implements OnInit, OnDestroy {

  suppotedLangs: string[];
  currentLangIndex = 0;
  unsubscribeFromRTCounters: any;
  unsubscribeFromNotificationCounter: any;
  currentUserId;
  rtCounter: RTCounterInterface;
  @ViewChild('bellIcon') bellIcon;
  $destroy = new Subject();
  currentTab = '';

  constructor(
    private commonService: CommonService,
    private translateService: TranslateService,
    private authService: AuthenticationService,
    private router: Router,
    public notificationsService: NotificationsService,
    public activatedRoute: ActivatedRoute,
    public userPermissionsService: UserPermissionsService,
    private userInfoService: UserInfoService
  ) {
    this.setCurrentRoute(this.router.url);
  }

  ngOnInit(): void {
    this.userInfoService.loggedUser
      .pipe(takeUntil(this.$destroy))
      .subscribe(user => {
      if (user && user.userID && (this.currentUserId !== user.userID)) {
        this.currentUserId = user.userID;
        this.getRTCounters();
      }
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        takeUntil(this.$destroy)
       )
      .subscribe((route: NavigationStart) => {
        this.setCurrentRoute(route.url);
      });
  }

  navigateToRootInventory() {
    this.commonService.navigateToRootInventory();
  }

  navigateTo(route: string) {
    if (this.commonService.editingForm && this.commonService.editingForm.dirty) {
      this.commonService.confirmClosing();

      const subscriber = this.commonService.onConfirmationClosed.subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.router.navigate([route]);
        }
      });
    } else {
      this.router.navigate(['user', route]);
    }
  }

  setCurrentRoute(url: string) {
    if (url.includes('inventory')) {
      this.currentTab = 'inventory';
      return;
    }
    if (url.includes('/settings/')) {
      this.currentTab = 'settings';
      return;
    }
    const splitted = url.split('/');
    this.currentTab = splitted[splitted.length - 1];
  }

  getRTCounters() {
    if (this.unsubscribeFromRTCounters) {
      this.unsubscribeFromRTCounters();
    }
    this.unsubscribeFromRTCounters = this.notificationsService.getRTCounters((counterData) => {
      if (this.rtCounter && this.rtCounter.notifications && this.rtCounter.notifications < counterData.notifications) {
        if (this.bellIcon) {
          this.bellIcon.nativeElement.classList.remove('animation');
          setTimeout(() => {
            this.bellIcon.nativeElement.classList.add('animation');
          }, 100);
        }
      }
      this.rtCounter = counterData;

    });
  }

  changeLang() {
    const currentLang = this.translateService.currentLang;
    this.currentLangIndex = this.suppotedLangs.findIndex(lang => lang === currentLang);
    this.currentLangIndex++;

    if (this.currentLangIndex >= this.suppotedLangs.length) {
      this.currentLangIndex = 0;
    }

    this.translateService.use(this.suppotedLangs[this.currentLangIndex]);
    localStorage.setItem('lang', this.suppotedLangs[this.currentLangIndex]);
  }

  ngOnDestroy() {
    if (this.unsubscribeFromRTCounters) {
      this.unsubscribeFromRTCounters();
    }
    this.$destroy.next();
  }

  logOut() {
    this.authService.logOut();
  }
}
