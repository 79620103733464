<img src="../../../assets/images/login-background.svg" class="background" [ngStyle]="{'height': screenHeight + 'px'}">

<div class="form-wrapper" [ngStyle]="{'height': screenHeight + 'px'}">
  <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="screenWidth > 600">
    <div class="header">
      <img class="logo" src="assets/logos/shelfit.svg" alt="logo">
    </div>

    <input formControlName="email" type="email" [placeholder]="'login-form.email' | translate">
    <div style="position: relative; margin: 0">
      <input formControlName="password" [type]="showPass ? 'text' : 'password'" [placeholder]="'login-form.password-ph' | translate">
      <i class="pass-eye no-select" [class.eye]="!showPass" [class.closed-eye]="showPass" (click)="togglePassword()"></i>
    </div>

    <button class="submit-btn" type="submit" [disabled]="isLoading">
      {{ 'buttons.login' | translate }}
    </button>
    <a class="redirect-link register-link web" role="button" (click)="resendConfirmEmail()" style="margin-bottom: 25px;" *ngIf="showResendConfirmationEmail">
      {{ 'login-form.resend-confirmation-email' | translate }}
    </a>
    <a class="redirect-link register-link web" role="button" (click)="goToRegistration()">
      {{ 'login-form.register' | translate }}
    </a>

    <div class="footer">
      <div class="checkbox">
        <input id="remember" formControlName="remember" type="checkbox">
        <label for="remember" class="box"></label>
        <label for="remember">{{ 'login-form.remember' | translate }}</label>
      </div>

      <a routerLink="/pass-recover" style="padding-bottom: 3px">{{ 'login-form.forgot-pass' | translate }}</a>
    </div>

    <a class="redirect-link register-link mobile" role="button" (click)="goToRegistration()">
      {{ 'login-form.register' | translate }}
    </a>
  </form>

  <form class="closed-register-access" *ngIf="screenWidth <= 600">
    <div class="header">
      <img class="logo" src="assets/logos/shelfit.svg" alt="logo">
    </div>

    <div class="main-content">
      <span [innerHTML]="'welcome-message.web-not-supported' | translate"></span>

      <img src="assets/images/dont_use_mobile_phone_gray.png" style="max-width: 70px;">

      <span [innerHTML]="'welcome-message.you-can-use' | translate"></span>

      <button class="submit-btn mobile" (click)="goToApp()">
        {{ 'welcome-message.android-app'  | translate}}
        <i class="android-app"></i>
      </button>

      <span [innerHTML]="'welcome-message.or' | translate"></span>
  
      <a class="redirect-link register-link mobile" role="button" (click)="goToRegistration()">
        {{ 'login-form.register' | translate }}
      </a>
    </div>
  </form>

  <up-msg-snack style="position: absolute"></up-msg-snack>
</div>

