<img src="../../../assets/images/login-background.svg" class="background" [ngStyle]="{'height': screenHeight + 'px'}">

<div class="form-wrapper" [ngStyle]="{'height': screenHeight + 'px'}">
  <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="userEmail">
    <div class="header">
      <img class="logo" src="assets/logos/shelfit.svg" alt="logo">
    </div>

    <p class="set-pass-message" *ngIf="!workerSetup">
      {{ 'set-new-password-form.set-new-password-msg' | translate : {email: userEmail} }}
    </p>

    <p class="set-pass-message" *ngIf="workerSetup">
      {{ 'set-new-password-form.worker-set-new-password-msg' | translate : {email: userEmail} }}
    </p>

    <div class="row">
      <div class="col-12 pass-input-wrp">
        <input formControlName="newPassword" [type]="showPass ? 'text' : 'password'" [placeholder]="'set-new-password-form.password' | translate">
        <i class="pass-eye no-select" [class.eye]="!showPass" [class.closed-eye]="showPass" (click)="showPass = !showPass"></i>
      </div>
      <div class="col-12 pass-input-wrp">
        <input formControlName="confirmPassword" [type]="showConfirmPass ? 'text' : 'password'" [placeholder]="'set-new-password-form.confirm-password' | translate">
        <i class="pass-eye no-select" [class.eye]="!showConfirmPass" [class.closed-eye]="showConfirmPass" (click)="showConfirmPass = !showConfirmPass"></i>
      </div>
    </div>

    <button class="submit-btn" type="submit" [disabled]="isLoading">
      {{ (workerSetup ? 'set-new-password-form.continue-login' : 'set-new-password-form.set-new-password') | translate }}
    </button>
  </form>

  <div *ngIf="!userEmail" class="link-expired">
    <div class="header">
      <img class="logo" src="assets/logos/shelfit.svg" alt="logo">
    </div>
    <p>{{ errorMessage }}</p>
  </div>
</div>



