import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {routes} from './app-routes';
import {LayoutComponent} from './core/layout/layout.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreMenuComponent} from './core/core-menu/core-menu.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LogInComponent} from './core/login/log-in.component';
import {ReactiveFormsModule} from '@angular/forms';
import {PassRecoverComponent} from './core/pass-recover/pass-recover.component';
import {UnderDevPageComponent} from './core/under-dev-page/under-dev-page.component';
import {NgxImageCompressService} from 'ngx-image-compress';
import {ClipboardModule} from 'ngx-clipboard';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AuthenticationService} from './core/authentication.service';
import { SetNewPasswordComponent } from './core/set-new-password/set-new-password.component';
import { FirebaseActionsComponent } from './core/firebase-actions/firebase-actions.component';
import { VerifyEmailComponent } from './core/verify-email/verify-email.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { heLocale } from 'ngx-bootstrap/locale';
import { ruLocale } from 'ngx-bootstrap/locale';
import {NgxMaskModule} from 'ngx-mask';
import { PrivacyPolicyComponent } from './core/privacy-policy/privacy-policy.component';
import { SubscriptionExpiredComponent } from './core/subscription-expired/subscription-expired.component';

defineLocale('he', heLocale);
defineLocale('ru', ruLocale);

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    CoreMenuComponent,
    LogInComponent,
    PassRecoverComponent,
    UnderDevPageComponent,
    SetNewPasswordComponent,
    FirebaseActionsComponent,
    VerifyEmailComponent,
    PrivacyPolicyComponent,
    SubscriptionExpiredComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    RouterModule.forRoot(routes),
    NgbModule,
    HttpClientModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, 'assets/i18n/', '.json'),
        deps: [HttpClient]
      },
      isolate: false
    }),
    // SettingsModule
  ],
  providers: [
    AuthenticationService,
    NgxImageCompressService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
