<div *ngIf="!showConfirmationScreen && !showRemoveUsersScreen" class="workers-content">
  <p class="page-title settings-title" style="margin: 41px -50px 102px;">
    {{ 'settings.workers-number-page.title' | translate }}
  </p>

  <p class="text" style="margin-bottom: 38px;"
     [innerHTML]="'settings.workers-number-page.your-plan' | translate:{
     userPlan: translatedUserPlan,
     workersNb: includedInPlanUsers
     }"></p>
  <p class="text accent" style="margin-bottom: 104px;"
     [innerHTML]="'settings.workers-number-page.workers-in-addition-number' | translate:{
     userPlan: translatedUserPlan,
     addedWorkersNb: additionalUsersCount
     }"></p>
  <p class="text accent" style="margin-bottom: 113px;"
     [innerHTML]="'settings.workers-number-page.signed-up-users-number' | translate:{
     currentUsers: signedUpUsers,
     maxUsers: usersCount
     }"></p>

  <div class="users-input-container">
    <i class="decrement-user"
       [class.disabled]="usersCount === includedInPlanUsers"
       (click)="addUsers(-1)"></i>
    <span>{{ usersCount }}</span>
    <i class="increment-user"
       (click)="addUsers(1)"></i>
  </div>

  <div class="action-btn-container" style="width: 100%; text-align: center">
    <button class="up-btn up-btn-primary billing-content-btn"
            (click)="switchToConfirmationScreen()">
      {{ 'settings.workers-number-page.update-btn' | translate }}
    </button>
  </div>
</div>

<!--<div *ngIf="showRemoveUsersScreen" class="workers-content" style="height: 100%">-->
<!--  <p class="page-title settings-title" style="margin: 41px -50px 102px;">-->
<!--    {{ 'settings.workers-number-page.title' | translate }}-->
<!--  </p>-->

<!--  <p class="text with-blue" style="margin-bottom: 38px;"-->
<!--     [innerHTML]="'settings.workers-number-page.change-users' | translate:{-->
<!--     oldUsersCount: companyAdditionalUsers,-->
<!--     newUsersCount: usersCount - includedInPlanUsers-->
<!--     }">-->
<!--  </p>-->

<!--  <p *ngIf="signedUpUsers - usersCount > 0"-->
<!--     class="text with-red"-->
<!--     style="margin-bottom: 38px;"-->
<!--     [innerHTML]="'settings.workers-number-page.remove-users' | translate:{-->
<!--     count: signedUpUsers - usersCount-->
<!--     }">-->
<!--  </p>-->

<!--  <div>-->
<!--  <up-user-management (userRemoved)="onWorkerRemoved()"-->
<!--                      (isRemovingUser)="isLoading = true;"-->
<!--                      [removeOnly]="true">-->
<!--  </up-user-management>-->
<!--  </div>-->

<!--  <div class="full-width d-flex justify-content-center">-->
<!--    <button class="up-btn users-button"-->
<!--            [disabled]="signedUpUsers - usersCount > 0"-->
<!--            (click)="switchToConfirmationScreen()">-->
<!--      {{ 'settings.workers-number-page.update-users-number-button' | translate }}-->
<!--    </button>-->
<!--  </div>-->
<!--</div>-->

<div *ngIf="showConfirmationScreen" class="workers-content">
  <p class="page-title settings-title" style="margin: 41px -50px 120px;">
    {{ 'settings.workers-number-page.confirmation-title' | translate }}
  </p>

  <div class="center-container">
    <div class="cell" style="margin-bottom: 33px;">
      <label>{{ 'settings.workers-number-page.plan' | translate:{
          plan: companyPlan,
          planPrice: currentPlanPrice,
          currency: currencySymbol || '',
          period: 'settings.workers-number-page.month' | translate
      } }}</label>
    </div>

    <div class="cell" style="margin-bottom: 130px;">
      <label>
        {{ 'settings.workers-number-page.additional-worker' | translate }} x {{ usersCount - includedInPlanUsers }}
        --- {{currencySymbol}}{{userPrice * (usersCount - includedInPlanUsers)}}/{{ 'settings.workers-number-page.month' | translate }}
      </label>
    </div>

    <p class="total-price">
      {{ 'settings.workers-number-page.total' | translate:{
        price: totalPrice,
        currency: currencySymbol || '',
        period: 'settings.workers-number-page.month' | translate
      } }}
    </p>

    <button class="up-btn up-btn-primary billing-content-btn proceed-btn" (click)="updateUsersCount()">
      {{ 'settings.workers-number-page.proceed-btn' | translate}}
    </button>
  </div>
</div>

<ng-container *ngIf="isLoading">
  <div style="position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(0,0,0,0.37);">
  </div>
  <div class="full-width d-flex justify-content-center mt-4">
    <div class="up-spinner-frame"></div>
  </div>
</ng-container>
