import { Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { LogInComponent } from './core/login/log-in.component';
import { AuthGuard } from './core/auth.guard';
import { UnderDevPageComponent } from './core/under-dev-page/under-dev-page.component';
import { PassRecoverComponent } from './core/pass-recover/pass-recover.component';
import { SetNewPasswordComponent } from './core/set-new-password/set-new-password.component';
import { VerifyEmailComponent } from './core/verify-email/verify-email.component';
import { FirebaseActionsComponent } from './core/firebase-actions/firebase-actions.component';
import { PrivacyPolicyComponent } from "./core/privacy-policy/privacy-policy.component";
import { PrivacyPolicyGuard } from './core/guards/privacy-policy.guard';
import { SubscriptionExpiredComponent } from './core/subscription-expired/subscription-expired.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LogInComponent
  },
  {
    path: 'register',
    loadChildren: () => import('./core/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'registration',
    loadChildren: () => import('./core/registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'subscription-expired',
    component: SubscriptionExpiredComponent
  },
  {
    path: 'pass-recover',
    component: PassRecoverComponent,
  },
  {
    path: 'pass-reset',
    component: SetNewPasswordComponent
  },
  {
    path: 'actions',
    component: FirebaseActionsComponent
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent
  },
  {
    path: 'user',
    component: LayoutComponent,
    canActivate: [AuthGuard, PrivacyPolicyGuard],
    children: [
      {
        path: 'inventory',
        canActivate: [AuthGuard, PrivacyPolicyGuard],
        loadChildren: () => import('./modules/inventory/inventory.module').then(module => module.InventoryModule)
      },
      {
        path: 'notifications',
        canActivate: [AuthGuard, PrivacyPolicyGuard],
        loadChildren: () => import('./modules/notifications/notifications.module').then(module => module.NotificationsModule)
      },
      {
        path: 'tasks',
        canActivate: [AuthGuard, PrivacyPolicyGuard],
        component: UnderDevPageComponent,
      },
      {
        path: 'preordered',
        canActivate: [AuthGuard, PrivacyPolicyGuard],
        loadChildren: () => import('./modules/preordered/preordered.module').then(module => module.PreorderedModule)
      },
      // {
      //   path: 'reports',
      //   canActivate: [AuthGuard, PrivacyPolicyGuard],
      //   component: UnderDevPageComponent
      // },
      // {
      //   path: 'history',
      //   canActivate: [AuthGuard, PrivacyPolicyGuard],
      //   component: UnderDevPageComponent,
      // },
      {
        path: 'removed',
        canActivate: [AuthGuard, PrivacyPolicyGuard],
        loadChildren: () => import('./modules/trash-bin/trash-bin.module').then(module => module.TrashBinModule)
      },
      {
        path: 'tutorial',
        canActivate: [AuthGuard, PrivacyPolicyGuard],
        loadChildren: () => import('./modules/tutorial/tutorial.module').then(module => module.TutorialModule)
      },
      {
        path: 'settings',
        canActivate: [AuthGuard, PrivacyPolicyGuard],
        loadChildren: () => import('./modules/settings/settings.module').then(module => module.SettingsModule)
      },
      {
        path: '**',
        redirectTo: 'inventory/Main'
      },
      {
        path: '',
        redirectTo: 'inventory/Main',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/user/inventory/Main'
  },
  {
    path: '',
    redirectTo: '/user/inventory/Main',
    pathMatch: 'full'
  }
];
