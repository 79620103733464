import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentSnapshot, Query } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { RTCounterInterface } from 'src/app/shared/interfaces/rt-counter.interface';
import { UserInfoService } from '../../core/user-info.service';
import { ItemInterface } from '../../shared/interfaces/item.interface';
import { NotificationInterface } from '../../shared/interfaces/notification.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {


  // private notificationsUrl = '/company/notifications';
  // private notificationsArr: NotificationInterface[] = [];
  // public notifications = new BehaviorSubject<NotificationInterface[]>([]);
  // public newNotificationSubject = new Subject<NotificationInterface>();
  // private notificationUrl = (id) => `/socket/notification/${id}`;

  constructor(
    private db: AngularFirestore,
    private userInfoService: UserInfoService,
    // private http: HttpClient,
    // private translateService: TranslateService,
    // private snackBarService: SnackBarService,
  ) {
    // this.updateNotifications();
    // http.get('assets/flags/en.jpg', {responseType: 'arraybuffer'}).subscribe();
  }

  getProductViaPathSubscription(path: string, callback) {
    return this.db.doc(path).ref.onSnapshot(callback);
  }

  getProductViaPath(path: string) {
    return this.db.doc(path).ref.get();
  }

  getProductViaIdSubscription(id: string, callback) {
    const companyId = this.userInfoService.company.id;
    return this.db.collection(`/Companies/${companyId}/Inventory`).doc(id).ref.onSnapshot(callback);
  }

  getProductViaId(id: string) {
    const companyId = this.userInfoService.company.id;
    return this.db.firestore.collection(`/Companies/${companyId}/Inventory`).doc(id).get();
  }

  getNotifications(
    limit: number,
    sort: { key: string, order: 'asc' | 'desc' },
    callback: (snapshot: firebase.firestore.QuerySnapshot<NotificationInterface>) => void,
    errorCallback?: any
  ) {

    // const usersAccess = this.userInfoService.user.foldersIDAccess;

    let query: Query<NotificationInterface> = this.db.collection<NotificationInterface>(`/Companies/${this.userInfoService.company.id.toString()}/Notifications`).ref;

    // if (usersAccess.findIndex(x => x === '0') < 0) {
    //  query = query.where('rootFolderID', 'in', usersAccess);
    // }

    query = query.orderBy(sort.key, sort.order);
    query = query.limit(limit);
    return query.onSnapshot({ includeMetadataChanges: true }, callback, errorCallback);
  }

  addNotification(notification: NotificationInterface) {
    const companyId = this.userInfoService.company.id;
    return this.db.firestore.collection(`/Companies/${companyId}/Notifications`).doc(notification.id).get()
      .then(async (doc) => {
        const actions = [];
        await this.db.firestore.collection(`/Companies/${companyId}/Notifications`).doc(notification.id).set(notification);
        if (!doc.exists) {
          const usersIdsToAddNotifications = await this.getUserIdsWhoAccessItem(companyId, notification);
          usersIdsToAddNotifications.forEach(userId => {
            console.log("+userId");
            this.syncUserRTCounter(companyId, +userId);
          });
        }

        return Promise.all(actions).then(result => {
          return notification;
        });
      });
  }

  removeNotification(source: NotificationInterface) {
    const companyId = this.userInfoService.company.id;

    return this.db.firestore.collection(`/Companies/${companyId}/Notifications`).doc(source.id).get()
      .then(async (doc) => {
        const actions = [];
        if (doc.exists) {
          await this.db.firestore.collection(`/Companies/${companyId}/Notifications`).doc(source.id).delete();
          const usersIdsToAddNotifications = await this.getUserIdsWhoAccessItem(companyId, source);
          usersIdsToAddNotifications.forEach(userId => this.syncUserRTCounter(companyId, +userId));
        }

        return Promise.all(actions).then(result => {
          return result;
        });
      });
  }

  public async updateNotification(notification: NotificationInterface) {
    alert("UPDATE Notification");
    await this.db.firestore.collection(`/Companies/${this.userInfoService.company.id}/Notifications`).doc(notification.id).set(notification);
  }

  deleteAllNotification() {
    return this.db.firestore.collection(`/Companies/${this.userInfoService.company.id}/Notifications`).get()
      .then(async (docs) => {
        for await (const doc of docs.docs) {
          const notification = doc.data();
          const item = await this.getProductViaId(notification.id)
            .then(itemDoc => itemDoc.exists && itemDoc.data()) as ItemInterface;
          await this.removeNotification(this.convertItemToNotification(item));
        }
      });
  }

  private async getUserIdsWhoAccessItem(companyId: number, item: NotificationInterface) {
    const usersAccess = await this.db.firestore.collection(`/Companies/${companyId}/UsersAccess`).doc('Access').get()
      .then(res => {
        return res.data();
      });

    return Object.keys(usersAccess).map(key => {
      console.log('getUserIdsWhoAccessItem', key, usersAccess[key], item.rootFolderId, item.parentFolderID);
      if (usersAccess[key][0] === '0' || usersAccess[key].findIndex(a => companyId === a || item.rootFolderId === a || item.parentFolderID === a) >= 0) {
        return key;
      } else {
        return null;
      }
    }).filter(x => x);
  }

  getRTCounters(callback: (res: RTCounterInterface) => void) {
    const companyId = this.userInfoService.user.companyID;
    const userId = this.userInfoService.user.userID;
    return this.db.doc(`/Companies/${companyId.toString()}/RTCounters/${userId.toString()}`)
      .ref.onSnapshot((res: DocumentSnapshot<RTCounterInterface>) => {
        const data = res.data();
        this.syncUserRTCounter(companyId, userId);
        return callback(data);
      });
  }

  getUserNotificationsCount(callback: (res: number) => void){
    const companyId = this.userInfoService.user.companyID;
    const userId = this.userInfoService.user.userID;
    return new Promise(async (resolve, reject) => {
      await this.db.firestore.collection(`/Companies/${companyId}/Notifications`)
        .get().then((notificationsRes) => {

          const usersAccess = this.userInfoService.user.foldersIDAccess;
          const hasRootAccess = usersAccess.indexOf('0') !== -1;
          let notifications = 0;
          if (hasRootAccess) {
            notifications = notificationsRes.docs.length;
          } else {
            notifications = notificationsRes.docs.filter(element => {
              if (element.id.includes(userId + '-')) {
                return true;
              }
              return false;
            }).length;
          }
          this.db.firestore.collection(`/Companies/${companyId}/RTCounters`).doc(userId.toString()).set({
            notifications
          }, { merge: true }).then(() => resolve());
          return callback(notifications);
        });
    });
  }

  private syncUserRTCounter(companyId: number, userId: number): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await this.db.firestore.collection(`/Companies/${companyId}/Notifications`)
        .get().then((notificationsRes) => {

          const usersAccess = this.userInfoService.user.foldersIDAccess;
          const hasRootAccess = usersAccess.indexOf('0') !== -1;
          let notifications = 0;
          if (!hasRootAccess) {
            notifications = notificationsRes.docs.filter(element => {
              const notification = element.data();
              if ( usersAccess.includes(notification.rootFolderId)) {
                return true;
              }
              return false;
            }).length;
          } else {
            notifications = notificationsRes.docs.length;
          }
          this.db.firestore.collection(`/Companies/${companyId}/RTCounters`).doc(userId.toString()).set({
            notifications
          }, { merge: true }).then(() => resolve());
        });
    });
  }

  public convertItemToNotification(item: ItemInterface) {
    const notification: NotificationInterface = {
      id: item.id,
      name: item.name,
      quantity: item.quantity,
      parentFolderID: item.parentFolderID,
      lowQuantity: item.lowQuantity,
      image: item.images[0] || '',
      rootFolderId: item.rootFolderId
    };
    return notification;
  }

  // updateNotifications() {
  //   this.getNotifications().pipe(
  //     catchError(err => of([]))
  //   ).subscribe((notifications: any) => {
  //     const actualNotifications = this.filterFalsyNotifications(notifications);
  //     this.notifications.next(actualNotifications);
  //     this.notificationsArr = actualNotifications;
  //   });
  // }
  //
  // filterFalsyNotifications(notifications: NotificationInterface[]) {
  //   return notifications.filter(notification => {
  //     if (notification.low_stock_qt < notification.quantity) {
  //       this.deleteNotification(notification.notification_id, false);
  //     }
  //
  //     return notification.low_stock_qt >= notification.quantity;
  //   });
  // }
  //
  // getNotifications(): Observable<NotificationInterface[]> {
  //   return this.http.get<NotificationInterface[]>(this.notificationsUrl);
  // }
  //
  // newNotification(notification) {
  //   if (notification && notification.item_id) {
  //     this.notificationsArr.unshift(notification);
  //     this.notifications.next(this.notificationsArr);
  //     this.newNotificationSubject.next(notification);
  //   }
  // }
  //
  // onNotificationRemoved(notificationId) {
  //   this.notificationsArr = this.notificationsArr.filter(not => {
  //     return (+notificationId) !== (+not.notification_id);
  //   });
  //
  //   this.notifications.next(this.notificationsArr);
  // }
  //
  // deleteNotification(notificationId, showResultMsg = true) {
  //   const deleteRequest = this.http.delete(this.notificationUrl(notificationId)).pipe(
  //     catchError(err => {
  //       return of({status: false});
  //     })
  //   );
  //
  //   deleteRequest.subscribe((res: any) => {
  //     if (res && res.status) {
  //       if (showResultMsg) {
  //         const msg = this.translateService.instant('snackbar-messages.notification-read');
  //         this.snackBarService.alert(msg);
  //       }
  //
  //       this.notificationsArr = this.notificationsArr.filter(not => {
  //         return notificationId !== not.notification_id;
  //       });
  //
  //       this.notifications.next(this.notificationsArr);
  //     }
  //   });
  //
  //   return deleteRequest;
  // }
  //
  // deleteAllNotification() {
  //   const ids = this.notificationsArr.map(n => n.notification_id).join(',');
  //
  //   this.http.delete(this.notificationUrl(ids)).pipe(
  //     catchError(err => {
  //       return of({status: false});
  //     })
  //   ).subscribe((res: any) => {
  //     if (res && res.status === true) {
  //       this.notificationsArr = [];
  //       this.notifications.next(this.notificationsArr);
  //
  //       const msg = this.translateService.instant('snackbar-messages.notifications-read');
  //       this.snackBarService.alert(msg);
  //     }
  //   });
  // }
}

