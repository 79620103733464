<div [class]="isRTL ? 'RTL' : 'LTR'">
  <router-outlet *ngIf="!settingFirestore && !(firestoreError && showMultipleTabsError)"></router-outlet>


  <div *ngIf="firestoreError && showMultipleTabsError" class="full-width d-flex justify-content-center mt-4">
    <!-- {{firestoreError}} -->
    <up-multiple-tabs-opened-warn></up-multiple-tabs-opened-warn>
  </div>

  <up-side-popover-screen #planWrapper [ignoreChanges]="true"
                          *ngIf="showChangePlanSidebar"
                          (onClose)="hidePlanSidebar()">
    <up-change-plan *ngIf="!paymentChargeScreen"
                    [currentPlanId]="currentCompanyPlanId"
                    [wrapperSidebar]="planWrapper"
                    [isLoading]="updatingCompanyPlan"
                    [currentYearlyPeriod]="currentPaymentPeriod === 'year'"
                    (choosePlan)="changePlan($event)">
    </up-change-plan>

    <up-payment-charge *ngIf="paymentChargeScreen"
                       (onSubmit)="changeCompanyPlan()"
                       [wrapperSidebar]="planWrapper"
                       [isLoading]="updatingCompanyPlan"
                       [companyPlan]="changingPlanTo">
    </up-payment-charge>
  </up-side-popover-screen>

  <up-side-popover-screen #workersWrapper
                          *ngIf="workersNumberSidebar"
                          (onClose)="hideWorkersSidebar()">
    <up-workers-number [wrapper]="workersWrapper"></up-workers-number>
  </up-side-popover-screen>

  <up-downgrade-popup *ngIf="showDowngradePopup"
                      [planId]="changingPlanTo"
                      (onConfirm)="showPaymentChargeScreen()"
                      (onCancel)="this.showDowngradePopup = false">
  </up-downgrade-popup>

  <div class="modal-container" *ngIf="showContactUsModal">
    <div class="background" (click)="showContactUsModal = false">
    </div>

    <div class="modal-window">
      <div class="modal-window-content">
        <p>{{ 'other.change-plan-contact-us' | translate}}</p>

        <div>
          <i class="big-envelope"></i>
        </div>

        <button (click)="mailTo()" style="text-transform: uppercase">{{ 'other.contact-us' | translate }}</button>
      </div>
    </div>

    <i class="close-selector slider-close" (click)="showContactUsModal = false"></i>
  </div>
  <div class="modal-container" *ngIf="showExpiredPlanModal">
    <div class="background" (click)="showExpiredPlanModal = false">
    </div>

    <div class="modal-window">
      <div class="modal-window-content">

        <div>
          <i class="lock"></i>
        </div>

        <p>{{ 'other.expired-plan-message' | translate}}</p>

        <!-- <button (click)="goToBilling()" style="text-transform: uppercase">{{ 'other.move-to-payment-update' | translate }}</button> -->
        <up-upgrade-section
          (openContactModal)="onOpenContactModal($event)"
        ></up-upgrade-section>
      </div>
    </div>

    <i class="close-selector slider-close" (click)="showExpiredPlanModal = false"></i>
  </div>

  <p *ngIf="showVersion" class="app-version">v {{appVersion}}</p>
</div>

<div class="modal-container" *ngIf="showContactUsListModal">
  <div class="background" (click)="onCloseContactUsModal()">
  </div>

  <div class="modal-window">
    <div class="modal-window-content">
      <p>{{ 'contact-us-popup.title' | translate }}</p>

      <div class="available-contact-list">
        <button (click)="contacUsPopup()" class="email-item">
          <img src="assets/images/payment_opt_email_{{pageLang}}.svg">
        </button>
        <a [href]="whatsAppLink" class="whatsapp-item">
          <img src="assets/images/payment_opt_whatsapp.svg">
        </a>
        <a [href]="telegramAppLink" class="telegram-item">
          <img src="assets/images/payment_opt_telegram.svg">
        </a>
      </div>
      <div class="available-contact-back-section">
        <button class="available-contact-back" (click)="backToUpgradeModal()"> <img src="assets/images/back_btn.svg"> {{ 'contact-us-popup.back-button' | translate }}</button>
      </div>

    </div>
  </div>

  <i class="close-selector slider-close" (click)="onCloseContactUsModal()"></i>
</div>

<!-- Modal for Contact Us section -->
<div class="modal-container contact-us-modal-container" *ngIf="showContactModal">
  <div class="background" (click)="showContactModal = false">
  </div>

  <div class="modal-window">
    <div class="modal-window-content">
      <p>{{ 'contact-us-txt' | translate }}
        <b (click)="copyTextToClipboard()">support@shelfit.tech</b>
      </p>

      <div>
        <i class="big-envelope"></i>
      </div>

      <button (click)="mailTo()" style="text-transform: uppercase">{{ 'other.contact-us' | translate }}</button>
    </div>
  </div>

  <i class="close-selector slider-close" (click)="showContactModal = false"></i>
</div>
<!--<div class="gray-background" *ngIf="userInfoService.anotherDeviceUsed"></div>-->
<!--<up-confirm-modal *ngIf="userInfoService.anotherDeviceUsed"-->
<!--                  [msg]="'other.another-device-used' | translate"-->
<!--                  [confirmBtnText]="'buttons.ok' | translate"-->
<!--                  [showCancelButton]="false"-->
<!--                  [fixedPosition]="false"-->
<!--                  (confirm)="closeShowAnotherDevice()">-->
<!--</up-confirm-modal>-->
