<div class="comparison">
  <div class="container py-3 actions">
    <div class="row"  style="margin-top: -35px; background: white; padding-top: 35px;">
      <div class="col-lg-3">
        <label class="switch-lbl">
          <span class="on-off-text on-text">{{ 'choose-plan.monthly' | translate}}</span>
          <div class="material-switch">
            <input id="someSwitchOptionSuccess" name="someSwitchOption001" type="checkbox" [checked]="yearlyPeriod" (change)="yearlyPeriod = !yearlyPeriod"/>
            <label for="someSwitchOptionSuccess" class="label-success"></label>
          </div>
          <span class="on-off-text off-text">{{ 'choose-plan.yearly' | translate}}<p class="save_upto">{{ 'choose-plan.save-20' | translate}}</p></span>
        </label>
      </div>
      <div class="col-lg-3 mt-4">
        <div class="col compare-plan yearly freepackage show text-center">
          <div>
            <h4>{{ 'choose-plan.free' | translate}}</h4>
            <span class="old_price"></span>
            <h3>$0.00</h3>
            <span *ngIf="yearlyPeriod">{{ 'choose-plan.an-year' | translate}}</span>
            <span *ngIf="!yearlyPeriod">{{ 'choose-plan.a-month' | translate}}</span>
          </div>
          <div>
            <a class="btn"
               [class.active]="currentPlanId === 1"
               (click)="emitPlan(1)"
               role="button">{{ freeButtonPlanText }}</a>
          </div>
          <div>
            <a role="button" class="link-purchasenow" style="visibility: hidden;"></a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mt-4">
        <div class="col compare-plan yearly advancedpackage show text-center">
          <div>
            <h4>{{ 'choose-plan.advanced' | translate}}</h4>
            <span class="old_price">{{ yearlyPeriod ?  priceFormer('advanced', false) : '' }}</span>
            <h3>{{ '$' + prices.advanced[yearlyPeriod ? 'yearly' : 'monthly'].toFixed(2) }}</h3>
            <span *ngIf="yearlyPeriod">{{ 'choose-plan.an-year' | translate}}</span>
            <span *ngIf="!yearlyPeriod">{{ 'choose-plan.a-month' | translate}}</span>
          </div>
          <div>
            <a class="btn"
               [class.active]="currentYearlyPeriod === yearlyPeriod && currentPlanId === 2"
               (click)="emitPlan(2)"
               role="button">{{ advancedButtonPlanText }}</a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mt-4">
        <div class="col compare-plan yearly ultrapackage show text-center">
          <div>
            <h4>{{ 'choose-plan.premium' | translate}}</h4>
            <span class="old_price">{{ yearlyPeriod ? priceFormer('premium', false)  : '' }}</span>
            <h3>{{ '$' + prices.premium[yearlyPeriod ? 'yearly' : 'monthly'].toFixed(2) }}</h3>
            <span *ngIf="yearlyPeriod">{{ 'choose-plan.an-year' | translate}}</span>
            <span *ngIf="!yearlyPeriod">{{ 'choose-plan.a-month' | translate}}</span>
          </div>
          <div>
            <a class="btn"
               [class.active]="currentYearlyPeriod === yearlyPeriod && currentPlanId === 3"
               (click)="emitPlan(3)"
               role="button">{{ premiumButtonPlanText }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="tbl-compareplan yearly show">
      <table>
        <tbody>
        <tr>
          <td colspan="4" class="tbl-header">{{ 'choose-plan.data-storage' | translate}}</td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.items-limit' | translate}}</td>
          <td class="w-20 freepackage"><span>150</span></td>
          <td class="w-20 advancedpackage"><span>2500</span></td>
          <td class="w-20 ultrapackage"><span>{{ 'choose-plan.unlimited' | translate}}</span></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.sync-data' | translate}}</td>
          <td class="w-20 freepackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.cloud-storage' | translate}}</td>
          <td class="w-20 freepackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.restore-removed-data' | translate}}</td>
          <td class="w-20 freepackage"><span>40</span></td>
          <td class="w-20 advancedpackage"><span>70</span></td>
          <td class="w-20 ultrapackage"><span>100</span></td>
        </tr>
        </tbody>
      </table>
      <table>
        <tbody>
        <tr>
          <td colspan="4" class="tbl-header">{{ 'choose-plan.dashboard' | translate}}</td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.dashboard' | translate}}</td>
          <td class="w-20 freepackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.notifications' | translate}}</td>
          <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.tasks' | translate}}</td>
          <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        </tbody>
      </table>
      <table>
        <tbody>
        <tr>
          <td colspan="4" class="tbl-header">{{ 'choose-plan.user-permission' | translate}}</td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.free-users' | translate}}</td>
          <td class="w-20 freepackage"><span>1</span></td>
          <td class="w-20 advancedpackage"><span>4</span></td>
          <td class="w-20 ultrapackage"><span>6</span></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.each-additional-user' | translate}}</td>
          <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><span>3$</span></td>
          <td class="w-20 ultrapackage"><span>3$</span></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.set-permission' | translate}}</td>
          <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.user-activity-logs' | translate}}</td>
          <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><span>10</span></td>
          <td class="w-20 ultrapackage"><span>20</span></td>
        </tr>
        </tbody>
      </table>
      <table>
        <tbody>
        <tr>
          <td colspan="4" class="tbl-header">{{ 'choose-plan.item-details' | translate}}</td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.item-photos' | translate}}</td>
          <td class="w-20 freepackage"><span>1</span></td>
          <td class="w-20 advancedpackage"><span>4</span></td>
          <td class="w-20 ultrapackage"><span>6</span></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.labels' | translate}}</td>
          <td class="w-20 freepackage"><span>2</span></td>
          <td class="w-20 advancedpackage"><span>10</span></td>
          <td class="w-20 ultrapackage"><span>30</span></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.additional-fields' | translate}}</td>
          <td class="w-20 freepackage"><span>2</span></td>
          <td class="w-20 advancedpackage"><span>10</span></td>
          <td class="w-20 ultrapackage"><span>30</span></td>
        </tr>
        </tbody>
      </table>
      <table>
        <tbody>
        <tr>
          <td colspan="4" class="tbl-header">{{ 'choose-plan.reports' | translate}}</td>
        </tr>
        <!--	<tr>
            <td class="w-40">Delivery Report</td>
            <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
            <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
            <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
          </tr>-->
        <tr>
          <td class="w-40">{{ 'choose-plan.csv-reports' | translate}}</td>
          <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        </tbody>
      </table>
      <table>
        <tbody>
        <tr>
          <td colspan="4" class="tbl-header">{{ 'choose-plan.data-backup' | translate}}</td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.import-data' | translate}}</td>
          <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.export-data' | translate}}</td>
          <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        <!--	<tr>
            <td class="w-40">Google Drive / Dropbox Backup</td>
            <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
            <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
            <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
          </tr>-->
        </tbody>
      </table>
      <table>
        <tbody>
        <tr>
          <td colspan="4" class="tbl-header">{{ 'choose-plan.support' | translate}}</td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.email-support' | translate}}</td>
          <td class="w-20 freepackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.screenshare-support' | translate}}</td>
          <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.screenshare-training' | translate}}</td>
          <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="x-mark" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        </tbody>
      </table>
      <table>
        <tbody>
        <tr>
          <td colspan="4" class="tbl-header">{{ 'choose-plan.cusmotization' | translate}}</td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.your-company-logo' | translate}}</td>
          <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.currencies' | translate}}</td>
          <td class="w-20 freepackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        </tbody>
      </table>
      <table>
        <tbody>
        <tr>
          <td colspan="4" class="tbl-header">{{ 'choose-plan.barcodes' | translate}}</td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.search-by-scan' | translate}}</td>
          <td class="w-20 freepackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.swift-action-scanner' | translate}}</td>
          <td class="w-20 freepackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        <tr>
          <td class="w-40">{{ 'choose-plan.generate-custom-barcode' | translate}}</td>
          <td class="w-20 freepackage"><i class="x-mark" aria-hidden="true"></i></td>
          <td class="w-20 advancedpackage"><i class="check" aria-hidden="true"></i></td>
          <td class="w-20 ultrapackage"><i class="check" aria-hidden="true"></i></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <up-msg-snack></up-msg-snack>

</div>

<ng-container *ngIf="isLoading">
  <div style="position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(0,0,0,0.37);">
  </div>
  <div class="full-width d-flex justify-content-center mt-4">
    <div class="up-spinner-frame"></div>
  </div>
</ng-container>
