import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentPopupService {

  openPlanPopup = new EventEmitter();
  openUsersPopup = new EventEmitter();
  openContactUsPopup = new EventEmitter();
}
