<img src="../../../assets/images/login-background.svg" class="background" [ngStyle]="{'height': screenHeight + 'px'}">

<div class="form-wrapper" [ngStyle]="{'height': screenHeight + 'px'}">
  <form>
    <div class="header">
      <img class="logo" src="assets/logos/shelfit.svg" alt="logo">
    </div>
      <p class="warning-text-block">{{ 'other.multiple-tabs-opened-restriction' | translate }}</p>
    <div class="footer">
      <!-- <button class="submit-btn" type="submit" (click)="closeTab()">
        {{ 'other.close' | translate }}
      </button> -->
    </div>
  </form>


  <up-msg-snack></up-msg-snack>
</div>

