<img src="../../../assets/images/login-background.svg" class="background" [ngStyle]="{'height': screenHeight + 'px'}">

<div class="form-wrapper" [ngStyle]="{'height': screenHeight + 'px'}">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="header">
      <img class="logo" src="assets/logos/shelfit.svg" alt="logo">
    </div>

    <div *ngIf="!successfullySubmitted" class="recovering-form-inputs">
      <p class="recover-message">{{ 'pass-recover-form.reset-password-msg' | translate }}</p>

      <input formControlName="user_mail" type="email" [placeholder]="'pass-recover-form.enter-email' | translate">

      <button class="submit-btn" type="submit" [disabled]="isLoading">
        {{ 'pass-recover-form.reset-password' | translate }}
      </button>
    </div>
    <div *ngIf="successfullySubmitted" class="last-message">
      <p class="recover-message">{{ 'pass-recover-form.sent-to-email-msg' | translate }} &nbsp;<i class="mail"></i></p>
    </div>
    <a class="redirect-link" routerLink="/login">
      {{ 'pass-recover-form.back-to-login' | translate }}
    </a>
  </form>
</div>

<up-msg-snack style="position: absolute"></up-msg-snack>
