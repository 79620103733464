<ng-container *ngIf="company && !isLoading">
  <div *ngIf="showLangWidget" class="changeLanguage">
    <div class="lang-btn" (click)="focusElement($event)" id="lang-btn" *ngIf="showLangWidget">
      <label>{{ 'lang-widget.button' | translate }}&#8192;{{flagString}}</label>
    </div>

    <div class="drop-down">
      <ul>
        <li (mousedown)="setLanguage('en', $event)">
          <span>
          {{ 'lang-widget.languages.en' | translate }}
          </span>
          EN >
        </li>
        <li (mousedown)="setLanguage('he', $event)">
          <span>
          {{ 'lang-widget.languages.he' | translate }}
          </span>
          HE >
        </li>
        <li (mousedown)="setLanguage('ru', $event)">
          <span>
          {{ 'lang-widget.languages.ru' | translate }}
          </span>
          RU >
        </li>
      </ul>
    </div>
  </div>

  <a [routerLink]="'/user/settings/main-info'" class="main-info-link" *ngIf="company.id ">
    <label class="user-name" >{{ userName }}</label>
    <img [src]="companyLogo || DEFAULT_LOGO" class="user-logo">
  </a>

</ng-container>

<ng-container *ngIf="isLoading">
  <a [routerLink]="'/user/settings/main-info'" class="main-info-link">
    <label class="user-name" >{{ userName }}</label>
    <img
      [src]="companyLogo || DEFAULT_LOGO"
      class="user-logo" (error)="loaded()"
      (load)="loaded()">
  </a>
</ng-container>




