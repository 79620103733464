import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
class FirebaseLocalImageCache {
  public readonly emptyImageUrl = 'assets/images/empty.svg';
  private readonly _store: Map<string, string>;
  constructor() {
    console.log('FirebaseLocalImageCache, ctor');
    this._store = new Map<string, string>();
  }

  public getOrEmpty(name: string) {
    if (name === void 0) {
      return this.emptyImageUrl;
    }
    return this.get(name) || this.emptyImageUrl;
  }

  public get(name: string) {
    return this._store.get(name) || this.getFromLocalStorage(name);
  }

  public set(name: string, url: string) {
    this._store.set(name, url);
    this.setToLocalStorage(name, url);
  }

  public remove(name: string) {
    let item = this.getFromLocalStorage(name);
    if (item !== void 0) {
      localStorage.removeItem(name);
    }
    item = this._store.get(name);
    if (item !== void 0) {
      this._store.delete(name);
    }
  }

  private getFromLocalStorage(name: string) {
    try {
      return localStorage.getItem(name) || void 0;
    }
    catch {
      return void 0;
    }
  }

  private setToLocalStorage(name: string, url: string) {
    try {
      localStorage.setItem(name, url);
    }
    catch (ex) {
      console.log('setToLocalStorage ex =>', ex);
    }
  }

  public print() {
    console.log('imageCache =>', this._store);
  }
}

export { FirebaseLocalImageCache }
