import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { IInventoryItem } from '../shared/interfaces/IInventoryItem';
import { IInventorySnapshotCallback } from '../shared/interfaces/IInventorySnapshotCallback';
import { ISort } from '../shared/interfaces/ISort';
import { FirebaseLocalImageCache } from './FirebaseLocalImageCache';

class OneInventorySnapshot {

  private sorters = {
    type: {
      asc: (a: IInventoryItem, b: IInventoryItem) => { return a.type.localeCompare(b.type); },
      desc: (a: IInventoryItem, b: IInventoryItem) => { return b.type.localeCompare(a.type); }
    },
    name: {
      asc: (a: IInventoryItem, b: IInventoryItem) => { return a.name.localeCompare(b.name); },
      desc: (a: IInventoryItem, b: IInventoryItem) => { return b.name.localeCompare(a.name); }
    },
    lowerCaseName: {
      asc: (a: IInventoryItem, b: IInventoryItem) => { return a.name.localeCompare(b.name); },
      desc: (a: IInventoryItem, b: IInventoryItem) => { return b.name.localeCompare(a.name); }
    },
    notes: {
      asc: (a: IInventoryItem, b: IInventoryItem) => { return a.notes.localeCompare(b.notes); },
      desc: (a: IInventoryItem, b: IInventoryItem) => { return b.notes.localeCompare(a.notes); }
    },
    lowerCaseNotes: {
      asc: (a: IInventoryItem, b: IInventoryItem) => { return a.notes.localeCompare(b.notes); },
      desc: (a: IInventoryItem, b: IInventoryItem) => { return b.notes.localeCompare(a.notes); }
    },
    location: {
      asc: (a: IInventoryItem, b: IInventoryItem) => { return a.location.localeCompare(b.location); },
      desc: (a: IInventoryItem, b: IInventoryItem) => { return b.location.localeCompare(a.location); }
    },
    lowerCaseLocation: {
      asc: (a: IInventoryItem, b: IInventoryItem) => { return a.lowerCaseLocation.localeCompare(b.lowerCaseLocation); },
      desc: (a: IInventoryItem, b: IInventoryItem) => { return b.lowerCaseLocation.localeCompare(a.lowerCaseLocation); }
    },
    quantity: {
      asc: (a: IInventoryItem, b: IInventoryItem) => { return a.quantity - b.quantity; },
      desc: (a: IInventoryItem, b: IInventoryItem) => { return b.quantity - a.quantity; }
    },
    price: {
      asc: (a: IInventoryItem, b: IInventoryItem) => { return a.price - b.price; },
      desc: (a: IInventoryItem, b: IInventoryItem) => { return b.price - a.price; }
    }
  }

  private readonly _store: Array<IInventoryItem>;
  private readonly _docs: Array<firebase.firestore.DocumentSnapshot<IInventoryItem>>;
  private limit: number;
  private queryLimit: number;
  private firstTimeLoading: boolean;

  private subscription: () => void;

  constructor(
    private imageCache: FirebaseLocalImageCache,
    private db: AngularFirestore,
    private companyId: number,
    private foldersIDAccess: Array<string>,
    public folderId: string,
    private sort: ISort,
    public onSnapshot: (snapshot: IInventorySnapshotCallback) => void
  )
  {
    this._store = [];
    this._docs = [];
    this.limit = 0;
    this.queryLimit = 0;
    this.firstTimeLoading = true;
  }

  public query(limit: number) {
    if (this.foldersIDAccess.length === 0) {
      this.firstTimeLoading = false;
      this.notify(false);
      return;
    }
    this.limit = limit;
    if (this.limit > this.queryLimit) {
      this.unsubscribe();

      this.queryLimit = this.limit;

      const hasRootAccess = this.foldersIDAccess.indexOf('0') !== -1;
      const collectionRef = this.db.collection<IInventoryItem>(`/Companies/${this.companyId}/Inventory`).ref;

      let query = !hasRootAccess && this.folderId === 'Main' ? collectionRef.where('id', 'in', this.foldersIDAccess) : collectionRef.where('parentFolderID', '==', this.folderId);
      query = query.orderBy(this.sort.key, this.sort.order).limit(this.queryLimit);
      console.log('subscription => ', this.folderId);
      this.subscription = query.onSnapshot({ includeMetadataChanges: true }, this._onSnapshot, ex => console.error('onSnapshot ex =>', ex));
    }
    else {
      this.notify();
    }
    return this;
  }

  private _onSnapshot = async (snapshot: firebase.firestore.QuerySnapshot<IInventoryItem>) => {
    const changes = snapshot.docChanges();
    for (const change of changes) {
      const doc = change.doc.data();
      let index = this._store.findIndex(item => item.id === change.doc.id);
      if (change.type === 'removed') {
        if (index !== -1) {
          this._store.splice(index, 1);
          this._docs.splice(index, 1);
        }
      }
      else {
        if (index === -1) {
          index = this._store.push(doc) - 1;
          this._docs.push(change.doc);
        }
        else {
          if (!change.doc.isEqual(this._docs[index])) {
            this._store[index] = doc;
            this._docs[index] = change.doc;
          }
        }
        if (this._store[index].images.length !== 0 && this._store[index].images[0].length !== 0) {
          this._store[index].imageURL = this.imageCache.getOrEmpty(this._store[index].images[0]);
        }
      }
    }
    this.firstTimeLoading = false;
    this.notify(snapshot.metadata.fromCache);
  }

  private notify(fromCache?: boolean) {
    if (this.onSnapshot === void 0) {
      return;
    }
    const store = this._store.slice(0);
    const sorted = store.sort(this.sorters[this.sort.key][this.sort.order]);
    this.onSnapshot({
      firstTimeLoading: this.firstTimeLoading,
      fromCache,
      size: sorted.length,
      limit: this.limit,
      items: sorted.slice(0, this.limit)
    });
  }

  public unsubscribe() {
    if (this.subscription !== void 0) {
      console.log('unsubscribe => ', this.folderId);
      this.subscription();
      this.subscription = void 0;
    } 
  }

  public stopNotify() {
    console.log('stopNotify => ', this.folderId);
    this.onSnapshot = void 0;
  }

  public destroy() {
    console.log('OneInventorySnapshot ', this.folderId);
    this.unsubscribe();
    this._store.length = 0;
    this._docs.length = 0;
    this.limit = 0;
    this.queryLimit = 0;
    this.firstTimeLoading = true;
  }
}

@Injectable({ providedIn: 'root' })
class InventorySnapshotsService {

  private companyId: number;
  private foldersIDAccess: Array<string>;
  private readonly _snapshots: Map<string, OneInventorySnapshot>;

  constructor(private db: AngularFirestore, private imageCache: FirebaseLocalImageCache) {
    this._snapshots = new Map<string, OneInventorySnapshot>();
  }

  public init(companyId: number, foldersIDAccess: Array<string> = ['0']) {
    this.companyId = companyId;
    this.foldersIDAccess = foldersIDAccess;
  }

  private createKey(folderId: string, sort: ISort) {
    return `${folderId}-${sort.key}-${sort.order}`;
  }

  public getOrAdd(folderId: string, sort: ISort, onSnapshot: (snapshot: IInventorySnapshotCallback) => void) {
    const key = this.createKey(folderId, sort);
    let item = this._snapshots.get(key);
    if (item === void 0) {
      this._snapshots.set(key, new OneInventorySnapshot(this.imageCache, this.db, this.companyId, this.foldersIDAccess, folderId, sort, onSnapshot));
      item = this._snapshots.get(key);
    }
    else {
      item.onSnapshot = onSnapshot;
    }
    return item;
  }

  public destroy() {
    for (const snapshot of this._snapshots) {
      snapshot[1].destroy();
    }
    this._snapshots.clear();
  }
}

export { InventorySnapshotsService, OneInventorySnapshot };
