import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { IImportReject, OnAzureResolveFn, OnCompleteFn, OnErrorFn, OnImportRejectFn, OnProgressFn } from '../firestore/interface';


@Injectable({
  providedIn: 'root'
})
export class FirebaseStoreService {

  //public readonly worker: Worker;

  //private readonly _communications: Array<ICommunicationObject>;

  //public static apiEndpoint = 'http://localhost:7071/api';
  // public static apiEndpoint = 'https://shelfit-production.azurewebsites.net/api'; //prod version
  // public static apiEndpoint = 'https://shelfit.azurewebsites.net/api'; //dev version

  public static apiEndpoint = environment.apiEndpoint;

  constructor(private afAuth: AngularFireAuth, private translateService: TranslateService) {
    //console.log('ctor FirebaseStoreService');
    //if (typeof Worker !== 'undefined') {
    //  this._communications = new Array<ICommunicationObject>();
    //  this.worker = new Worker('../app.worker', { type: 'module' });
    //  this.worker.onmessage = this.onMessage;
    //}
    //else {
    //  console.error('Worker not supported!!!');
    //}
  }

  private getIdToken() {
    return this.afAuth.currentUser.then(user => {
      return user.getIdToken();
    });
  }

  public initializeApp(email: string, password: string) {
    //this.worker.postMessage(JSON.stringify({ command: 'initializeApp', args: [email, password] }));
  }

  //private createCommunication(command: ICommunicationCommand, companyId: number, userEmail: string, args: ICommunicationArgs, onResolve: OnCompleteFn, onReject?: OnErrorFn, onProgress?: OnProgressFn) {
  //  const index = this._communications.push({
  //    communicationId: 0,
  //    command,
  //    companyId,
  //    userEmail,
  //    args,
  //    onProgress,
  //    onResolve,
  //    onReject
  //  }) - 1;
  //  this._communications[index].communicationId = index;
  //  return this._communications[index];
  //}

  //public postMessage(command: ICommunicationCommand, companyId: number, userEmail: string, args: ICommunicationArgs, onProgress?: OnProgressFn, onComplete?: OnCompleteFn, onError?: OnErrorFn) {
  //  const communication = this.createCommunication(command, companyId, userEmail, args, onProgress, onComplete, onError);
  //  this.worker.postMessage(JSON.stringify({ communicationId: communication.communicationId, command, companyId, userEmail, args }));
  //  return communication;
  //}

  //public onMessage = ({ data }: { data: string }) => {
  //  const message = <ICommunicationObject>JSON.parse(data);
  //  console.log('message', message);
  //  const communication = this._communications[message.communicationId];
  //  if (communication !== void 0) {
  //    if (communication.onProgress !== void 0 && message.args[0] === 'info') {
  //      communication.onProgress(message.args);
  //    }
  //    if (message.args[0] === 'complete') {
  //      communication.onResolve(message.args);
  //    }
  //    if (message.args[0] === 'error') {
  //      communication.onReject(message.args);
  //    }
  //  }
  //}

  public deleteTree(companyId: number, userEmail: string, documentIds: string[]) {
    return new Promise((resolve: OnAzureResolveFn, reject: OnErrorFn) => {
      this.getIdToken().then(token => {
        var body = new FormData();
        body.append('companyId', companyId.toString());
        body.append('userEmail', userEmail);
        body.append('documentIds', documentIds.join(','));
        body.append('idToken', token);

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 800000 );

        fetch(`${FirebaseStoreService.apiEndpoint}/Delete`, { method: 'POST', body, signal: controller.signal })
          .then(response => {
            if (response.ok) {
              response.json().then(json => resolve(json)).catch(ex => reject(ex));
            }
            else {
              response.json().then(json => reject(json)).catch(ex => reject(ex));
            }
          })
          .catch(ex => {
            reject(ex);
          });
      });
    });
  }

  public copyTree(companyId: number, userEmail: string, destinationFolderId: string, documentIds: string[], onProgress?: OnProgressFn) {
    return new Promise((resolve: OnCompleteFn, reject: OnErrorFn) => {
      this.getIdToken().then(token => {
        var body = new FormData();
        body.append('companyId', companyId.toString());
        body.append('userEmail', userEmail);
        body.append('destinationFolderId', destinationFolderId);
        body.append('documentIds', documentIds.join(','));
        body.append('idToken', token);

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 800000 );

        fetch(`${FirebaseStoreService.apiEndpoint}/Copy`, { method: 'POST', body, signal: controller.signal })
          .then(response => {
            if (response.ok) {
              response.json().then(json => resolve(json)).catch(ex => reject(ex));
            }
            else {
              response.json().then(json => reject(json)).catch(ex => reject(ex));
            }
          })
          .catch(ex => {
            reject(ex);
          });
      });
    });
  }

  public moveTree(companyId: number, userEmail: string, destinationFolderId: string, documentIds: string[], onProgress?: OnProgressFn) {
    return new Promise((resolve: OnAzureResolveFn, reject: OnErrorFn) => {
      this.getIdToken().then(token => {
        var body = new FormData();
        body.append('companyId', companyId.toString());
        body.append('userEmail', userEmail);
        body.append('destinationFolderId', destinationFolderId);
        body.append('documentIds', documentIds.join(','));
        body.append('idToken', token);

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 800000 );

        fetch(`${FirebaseStoreService.apiEndpoint}/Move`, { method: 'POST', body, signal: controller.signal })
          .then(response => {
            if (response.ok) {
              response.json().then(json => resolve(json)).catch(ex => reject(ex));
            }
            else {
              response.json().then(json => reject(json)).catch(ex => reject(ex));
            }
          })
          .catch(ex => {
            reject(ex);
          });
      });
    });
  }

  public restoreTree(companyId: number, userEmail: string, documentIds: string[], onProgress?: OnProgressFn) {
    return new Promise((resolve: OnAzureResolveFn, reject: OnErrorFn) => {
      this.getIdToken().then(token => {
        var body = new FormData();
        body.append('companyId', companyId.toString());
        body.append('userEmail', userEmail);
        body.append('documentIds', documentIds.join(','));
        body.append('idToken', token);

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 800000 );

        fetch(`${FirebaseStoreService.apiEndpoint}/Restore`, { method: 'POST', body, signal: controller.signal })
          .then(response => {
            if (response.ok) {
              response.json().then(json => resolve(json)).catch(ex => reject(ex));
            }
            else {
              response.json().then(json => reject(json)).catch(ex => reject(ex));
            }
          })
          .catch(ex => {
            reject(ex);
          });
      });
    });
  }

  public importDocuments(companyId: number, userEmail: string, file: File) {
    return new Promise((resolve: OnAzureResolveFn, reject: OnImportRejectFn) => {
      this.getIdToken().then(token => {
        var body = new FormData();
        body.append('companyId', companyId.toString());
        body.append('userEmail', userEmail);
        body.append('file', file);
        body.append('idToken', token);

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 800000 );

        fetch(`${FirebaseStoreService.apiEndpoint}/Import`, { method: 'POST', body, signal: controller.signal })
          .then(response => {
            if (response.ok) {
              response.json().then(json => resolve(json)).catch(ex => reject(ex));
            }
            else {
              if (response.status === 422) {
                response.blob().then((blob: Blob) => reject({ blob, fileName: file.name })).catch(ex => reject(ex));
              }
              else {
                response.json().then(json => reject(json)).catch(ex => reject(ex));
              }
            }
          })
          .catch(ex => {
            reject(ex);
          });
      });
    });
  }

  public exportDocuments(companyId: number, userEmail: string, documentIds: string[], customName: string = 'Inventory') {
    return new Promise((resolve: OnAzureResolveFn, reject: OnImportRejectFn) => {
      this.getIdToken().then(token => {
        var body = new FormData();
        body.append('companyId', companyId.toString());
        body.append('userEmail', userEmail);
        body.append('documentIds', documentIds.join(','));
        body.append('idToken', token);

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 800000 );

        fetch(`${FirebaseStoreService.apiEndpoint}/Export`, { method: 'POST', body, signal: controller.signal })
          .then(response => {
            if (response.ok) {
              const fileName = `Shelfit ${customName} ${moment().format("DD-MM-YYYY hh-mm-ss")}.xlsx`;
              response.blob().then((blob) => resolve({ blob, fileName })).catch(ex => reject(ex));
            }
            else {
              if(response.status === 400) {
                console.log('something went wrong here');
                reject({
                  fileName: 'N/A',
                  blob: undefined,
                  message: response.statusText
                })
              }
              response.json().then(json => reject(json)).catch(ex => reject(ex));
            }
          })
          .catch(ex => {
            console.log('something went wrong here')
            reject(ex);
          });
      });
    });
  }

  public importTemplate(companyId: number, userEmail: string, lang: string) {
    return new Promise((resolve: OnAzureResolveFn, reject: OnImportRejectFn) => {
      this.getIdToken().then(token => {
        var body = new FormData();
        body.append('companyId', companyId.toString());
        body.append('userEmail', userEmail);
        body.append('idToken', token);

        fetch(`${FirebaseStoreService.apiEndpoint}/ImportTemplate`, { method: 'POST', body })
          .then(response => {
            if (response.ok) {
              const fileName = `${this.translateService.instant('settings.import.template-name')}.xlsx`;
              response.blob().then((blob) => resolve({ blob, fileName })).catch(ex => reject(ex));
            }
            else {
              response.json().then(json => reject(json)).catch(ex => reject(ex));
            }
          })
          .catch(ex => {
            reject(ex);
          });
      });
    });
  }

  public removeCompany(companyId: number, userEmail: string) {
    return new Promise((resolve: OnAzureResolveFn, reject: OnErrorFn) => {
      this.getIdToken().then(token => {
        var body = new FormData();
        body.append('companyId', companyId.toString());
        body.append('userEmail', userEmail);
        body.append('idToken', token);

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 800000 );

        fetch(`${FirebaseStoreService.apiEndpoint}/RemoveCompany`, { method: 'POST', body, signal: controller.signal })
          .then(response => {
            if (response.ok) {
              response.json().then(json => resolve(json)).catch(ex => reject(ex));
            }
            else {
              response.json().then(json => reject(json)).catch(ex => reject(ex));
            }
          })
          .catch(ex => {
            reject(ex);
          });
      });
    });
  }
}
