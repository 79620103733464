<ul class="sidebar-menu">
  <li class="nav-item"
      [routerLink]="'/user/inventory/Main'"
      [class.active]="currentTab === 'inventory'"
      (click)="navigateToRootInventory()">
    <i class="box"></i>
    {{ 'core-menu.inventory' | translate }}
  </li>
  <li *ngIf="userPermissionsService.hasNotificationsAccess" class="nav-item"
      (click)="navigateTo('notifications')"
      [class.active]="currentTab === 'notifications'">
    <i #bellIcon *ngIf="rtCounter?.notifications" class="notifications_exists"></i>
    <i #bellIcon *ngIf="!rtCounter?.notifications" class="notifications_empty"></i>
    {{ 'core-menu.notifications' | translate }} {{ rtCounter?.notifications ? '(' + rtCounter?.notifications + ')' : '' }}
  </li>
  <li *ngIf="!userPermissionsService.hasNotificationsAccess" class="nav-item"
      (click)="navigateTo('notifications')"
      [class.active]="currentTab === 'notifications'">
    <i class="notifications_lock"></i>
    {{ 'core-menu.notifications' | translate }}
  </li>
  <li class="nav-item"
      (click)="navigateTo('tasks')"
      [class.active]="currentTab === 'tasks'">
    <i class="list"></i>
    {{ 'core-menu.tasks' | translate }}
  </li>
  <li class="nav-item"
      (click)="navigateTo('preordered')"
      [class.active]="currentTab === 'preordered'">
    <i class="preordered"></i>
    {{ 'core-menu.preordered' | translate }}
  </li>
  <!-- <li class="nav-item"
      (click)="navigateTo('reports')"
      [class.active]="currentTab === 'reports'">
    <i class="open-envelope"></i>
    {{ 'core-menu.delivery-reports' | translate }}
  </li> -->
  <!-- <li class="nav-item"
      (click)="navigateTo('history')"
      [class.active]="currentTab === 'history'">
    <i class="activity" style="width: 25px"></i>
    {{ 'core-menu.activity-history' | translate }}
  </li> -->
  <li class="nav-item"
      (click)="navigateTo('removed')"
      [class.active]="currentTab === 'removed'">
    <i class="bin"></i>
    {{ 'core-menu.removed' | translate }}
  </li>
  <li class="nav-item"
      (click)="navigateTo('tutorial')"
      [class.active]="currentTab === 'tutorial'">
    <i class="tutorial"></i>
    {{ 'core-menu.tutorial' | translate }}
  </li>
</ul>

<ul class="sidebar-menu bottom">
  <li class="nav-item"
      (click)="navigateTo('settings')"
      [class.active]="currentTab === 'settings'">
    <i class="settings"></i>
    {{ 'core-menu.settings' | translate }}
  </li>
  <li class="nav-item"
      (click)="logOut()">
    <i class="log-out"></i>
    {{ 'core-menu.log-out' | translate }}
  </li>
</ul>
