import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'up-multiple-tabs-opened-warn',
  templateUrl: './multiple-tabs-opened-warn.component.html',
  styleUrls: ['./multiple-tabs-opened-warn.component.scss']
})
export class MultipleTabsOpenedWarnComponent implements OnInit {
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  windowResize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private translateService: TranslateService,
    private snackbarService: SnackBarService,
  ) { }

  ngOnInit(): void {
  }

  closeTab(): void {
    // don't work in modern browsers :(
    // https://stackoverflow.com/questions/2076299/how-to-close-current-tab-in-a-browser-window
    // So we show only snackbar for it:

    this.snackbarService.alert(this.translateService.instant('other.tab-close-error'))
    // this.snackbarService.alert('Show err');


  }

}
