<div class="tools-header" style="height: 0">
  <div class="user-info" style="height: 51px">
    <up-user-icon></up-user-icon>
  </div>
</div>

<div class="dev-page-content">
  <img src="assets/big-logo.svg" alt="">

  <p class="m-0" [innerHTML]="'other.under-dev-page' | translate"></p>
</div>
