import {Component, EventEmitter, Input, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';


@Component({
  selector: 'up-upgrade-section',
  templateUrl: './upgrade-section.component.html',
  styleUrls: ['upgrade-section.component.scss']
})
export class UpgradeSectionComponent {

  @Output() openContactModal = new EventEmitter<boolean>();

  appLink ='';
  constructor() {
    this.appLink = environment.googleAppLink;
  }

  onOpenContactModal(event: boolean) {
    this.openContactModal.emit(event);
  }

}
