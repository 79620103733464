import { Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UserInfoService} from './core/user-info.service';
import {AuthenticationService} from './core/authentication.service';
import {SnackBarService} from './shared/services/snack-bar.service';
import {PaymentPopupService} from './payment-popup.service';
import {CompanyInterface} from './shared/interfaces/company.interface';
import {CompanyDataService} from './modules/settings/services/company-data.service';
import firebase from 'firebase/app';
import {AngularFirestore} from '@angular/fire/firestore';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {DOCUMENT} from '@angular/common';
import { filter } from 'rxjs/operators';
import { version } from '../../package.json';
import {environment} from "../environments/environment";
import {Clipboard} from "@angular/cdk/clipboard";

declare var gapi: any;

@Component({
  selector: 'up-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'user-panel';
  readonly appVersion: string = version;
  showVersion: boolean = true;
  supportedLanguages = ['en', 'ru', 'he'];
  readonly allowedRoutes: Array<string> = ['/verify-email', '/privacy-policy', '/pass-reset', '/login',
    '/regist', '/actions?mode=verifyEmail', '/actions?mode=resetPassword'];
  readonly actionsRoutes: Array<string> = ['/actions?mode=verifyEmail', '/actions?mode=resetPassword'];
  readonly versionDisplayRoutes: Array<string> = ['/verify-email', '/privacy-policy', '/pass-recover', '/login'];

  isRTL = false;
  test = false;
  showContactUsModal = false;
  showNewDeviceUsedModal = false;

  showExpiredPlanModal = false;
  isShownExpiredPlanModal = false;

  showChangePlanSidebar = false;
  paymentChargeScreen = false;
  currentCompanyPlan = '';
  company: CompanyInterface;
  currentPaymentPeriod: string;

  changingPlanTo: number;
  updatingCompanyPlan = false;
  changingPeriodTo: number;
  showDowngradePopup = false;
  isLoading = true;
  workersNumberSidebar = false;
  firestoreError = '';
  settingFirestore = true;

  isFirstRoutingEvent = true;
  showMultipleTabsError: boolean = false;
  actionsPage: boolean = false;
  showContactUsListModal = false;
  showContactModal = false;
  emailAppLink ='';
  whatsAppLink ='';
  telegramAppLink ='';
  pageLang ='en';


  // @HostListener('window:beforeunload', [ '$event' ])
  // unloadHandler(event) {
  //   const remember = localStorage.getItem('rememberUser') === 'true';
  //   if (!remember) {
  //     const lastOpenedTime = new Date().getTime();
  //     localStorage.setItem('lastOpenedTime', lastOpenedTime.toString());
  //   } else {
  //     localStorage.removeItem('lastOpenedTime');
  //   }
  // }
  worker: Worker;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
    private clipboardService: Clipboard,
    // private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    public userInfoService: UserInfoService,
    private paymentPopupService: PaymentPopupService,
    private companyDataService: CompanyDataService,
    private ngFirestore: AngularFirestore,
    private localeService: BsLocaleService,
    @Inject(DOCUMENT) private _document: any
  ) {
    const localStorageLang = localStorage.getItem('lang');
    const urlParams = new URLSearchParams(window.location.search);
    const urlLang = urlParams.get('lang');

    const changedLang = urlLang || localStorageLang;

    this.pageLang = changedLang || 'en';

    this.emailAppLink = environment.emailAppLink;
    this.whatsAppLink = environment.whatsAppLink;
    this.telegramAppLink = environment.telegramAppLink;
    const remember = this.getCookie('rememberUser') === 'true' ? true : false;

    // AngularFireModule.initializeApp(environment.firebase),

    this.ngFirestore.firestore.settings({
      cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
      merge: true
    });

    router.events
      .pipe(filter(x => x instanceof NavigationEnd))
      .subscribe(e => {
        this.showMultipleTabsError = this.allowedRoutes.every(x =>
          !(e as NavigationEnd).url.startsWith(x));
        this.showVersion = this.versionDisplayRoutes.some(x =>
          (e as NavigationEnd).url.startsWith(x));
        this.actionsPage = this.actionsRoutes.some(x =>
          (e as NavigationEnd).url.startsWith(x));
      });

    route.queryParams.subscribe(params => {
      if (this.isFirstRoutingEvent) {
        this.isFirstRoutingEvent = false;
      } else if (this.authenticationService.isLoggedIn && !remember) {
        this.authenticationService.logOut(!this.actionsPage && !(params['mode'] == 'resetPassword' || params['mode'] == 'verifyEmail'))
          .then(() => this.enableFirestore());
        return;
      }
      this.enableFirestore();
    });

    // if (authenticationService.isLoggedIn) {
    //   // notificationsService.updateNotifications();
    // }
  }

  private enableFirestore() {
    if (this.settingFirestore) {
      this.ngFirestore.firestore.enablePersistence({ synchronizeTabs: false })
        .then(() => this.firestoreError = '')
        .catch((err) => {
          console.error('firestore enablePersistence ex => ', err);
          if (err.code === 'failed-precondition') {
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time
            this.firestoreError = this.translateService.instant('other.multiple-tabs-opened');
          } else if (err.code === 'unimplemented') {
            // The current browser does not support all of the
            // features required to enable persistence
            this.firestoreError = 'Browser support';
          }
        })
        .finally(() => this.settingFirestore = false);
    }
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-shadowed-variable
    this.translateService.onLangChange.subscribe(({lang}) => {
      this.isRTL = lang === 'he';
    });

    this.translateService.addLangs(this.supportedLanguages);
    this.translateService.setDefaultLang('en');

    const localStorageLang = localStorage.getItem('lang');
    const urlParams = new URLSearchParams(window.location.search);
    const urlLang = urlParams.get('lang');

    const lang = urlLang || localStorageLang;

    if (lang && this.supportedLanguages.includes(lang)) {
      this.translateService.use(lang);
      this.translateService.reloadLang(lang);
      localStorage.setItem('lang', lang);
    }

    this.localeService.use(this.translateService.currentLang || 'en');

    this.translateService.onLangChange
      .subscribe(x => this._document.documentElement.lang = x.lang);

    setTimeout(() => {
      this.test = true;
    }, 100);

    this.paymentPopupService.openPlanPopup.subscribe(() => {
      this.showPlanSidebar();
    });

    this.paymentPopupService.openUsersPopup.subscribe(() => {
      this.showWorkersSidebar();
    });

    this.paymentPopupService.openContactUsPopup.subscribe(() => {
      this.showContactUsModal = true;
    });

    this.userInfoService.companyChanged.subscribe((company: CompanyInterface) => {
      if (company && company.plan){
        this.currentCompanyPlan = company.plan.planName;
        this.currentPaymentPeriod = 'month'; // company.payment_period;

        if (company.planExpiryDate) {
          if (!this.isShownExpiredPlanModal) {
            this.showExpiredPlanModal = true;
            this.isShownExpiredPlanModal = true;
          }
        }
      }
    });

    //window.onload = () => {
    //  this.requestPermission();
    //};
  }

  ngOnDestroy() {
    if (this.showNewDeviceUsedModal) {
      this.userInfoService.logOut();
    }
  }

  get currentCompanyPlanId() {
    return this.currentCompanyPlan === 'Premium' ? 3 : (this.currentCompanyPlan === 'Advanced' ? 2 : 1);
  }

  private setCookie = (name,value,days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  private getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  showPlanSidebar() {
    this.showChangePlanSidebar = true;
  }

  showWorkersSidebar() {
    this.workersNumberSidebar = true;
  }

  hidePlanSidebar() {
    this.showChangePlanSidebar = false;
  }

  changePlan({planId, yearly}) {
    this.showContactUsModal = true;
    this.hidePlanSidebar();
    return;

    // this.changingPlanTo = planId;
    // this.changingPeriodTo = yearly;
    // if (this.currentCompanyPlanId > planId) {
    //   this.showDowngradePopup = true;
    // } else {
    //   this.showPaymentChargeScreen();
    // }
  }

  showPaymentChargeScreen() {
    this.showDowngradePopup = false;
    this.paymentChargeScreen = true;

  }

  changeCompanyPlan() {
    this.showDowngradePopup = false;
    this.isLoading = true;
    this.updatingCompanyPlan = true;

    this.companyDataService.updateCompanyPlan(this.changingPlanTo)
      .then(() => {
          setTimeout(() => {
            this.showChangePlanSidebar = false;
            this.paymentChargeScreen = false;
          }, 300);

          const msg = this.translateService.instant('snackbar-messages.payment-changed');
          this.snackBarService.alert(msg);
      })
      .catch(() => {

      })
      .finally(() => {
          this.isLoading = false;
          this.updatingCompanyPlan = false;
      });
  }

  hideWorkersSidebar() {
    this.workersNumberSidebar = false;
  }

  mailTo() {
    window.open('mailto:support@shelfit.tech', '_blank');
  }

  goToBilling() {
    this.showExpiredPlanModal = false;
    this.router.navigate(['user', 'settings', 'billing']);
  }
  onOpenContactModal(event: boolean) {
    this.showContactUsListModal = event;
    this.showExpiredPlanModal = false;
  }

  backToUpgradeModal() {
    this.showContactUsListModal = false;
    this.showExpiredPlanModal = true;
  }
  onCloseContactUsModal() {
    this.showContactUsListModal = false;
  }

  contacUsPopup() {
    if(window.navigator.userAgent.includes("Chrome")) {
      this.showContactModal = true;
      this.showContactUsListModal = false;
    } else {
      window.open('mailto:support@shelfit.tech', '_blank');
    }
  }

  copyTextToClipboard() {
    this.clipboardService.copy("support@shelfit.tech");
    this.snackBarService.alert(this.translateService.instant('snackbar-messages.mail-has-been-copied'));
  }


  // closeShowAnotherDevice() {
  //   this.userInfoService.anotherDeviceUsed = false;
  //   // this.userInfoService.logOut().then(() => {
  //   //   this.userInfoService.anotherDeviceUsed = false;
  //   // });
  // }
}
