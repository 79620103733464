import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { IDoc } from '../firestore/interface';
import { IPreorderedDoc } from '../shared/interfaces/IPreorderedDoc';
import { IPreorderedSnapshotCallback } from '../shared/interfaces/IPreorderedSnapshotCallback';
import { FirebaseLocalImageCache } from './FirebaseLocalImageCache';
import { FoldersTree } from './FoldersTreeService';

@Injectable({ providedIn: 'root' })
class PreorderedSnapshotService {

  private inited: boolean;
  private readonly _store: Array<IPreorderedDoc>;
  private storeLoaded: boolean;

  constructor(private db: AngularFirestore, private imageCache: FirebaseLocalImageCache, private foldersTree: FoldersTree) {
    this.inited = false;
    this._store = [];
    this.storeLoaded = false;
  }
  
  private listener: () => void;
  private companyId: number;
  private foldersIDAccess: Array<string>;
  private hasRootAccess: boolean;
  
  public init(companyId: number, foldersIDAccess: Array<string> = ['0']) {
    if (this.inited) {
      return;
    }
    this.inited = true;
    this.companyId = companyId;
    this.foldersIDAccess = foldersIDAccess;
    this.hasRootAccess = foldersIDAccess.indexOf('0') !== -1;

    this.listener = this.db.collection<IDoc>(`/Companies/${this.companyId}/Inventory`).ref.where('savedQuantity', '>', 0).onSnapshot({ includeMetadataChanges: true }, this.onSnapshot, ex => console.error(ex));
  }

  public destroy() {
    console.log('PreorderedSnapshotService destroy');
    this.unsubscribe();
    if (this.listener !== void 0) {
      this.listener();
    }
    this.inited = false;
    this._store.length = 0;
    this.storeLoaded = false;
  }

  private onSnapshot = async (snapshot: firebase.firestore.QuerySnapshot<IDoc>) => {
    const changes = snapshot.docChanges();
    console.log('getSavedProducts', changes.length, snapshot.metadata.fromCache, snapshot.size);
    //aggregate items
    for (const change of changes) {
      const doc = change.doc.data();
      if (!this.hasRootAccess) {
        if (doc.parentFolderID === 'Main') {
          if (this.foldersIDAccess.indexOf(doc.id) === -1) {
            continue;
          }
        }
        else if (this.foldersIDAccess.indexOf(doc.rootFolderId) === -1) {
          continue;
        }
      }
      if (change.type === 'removed') {
        //remove
        let index = this._store.findIndex(item => item !== void 0 && item.id === doc.id);
        while (index !== -1) {
          this._store.splice(index, 1);
          index = this._store.findIndex(item => item !== void 0 && item.id === doc.id);
        }
        continue;
      }
      for (const clientName in doc.savedProducts) {
        const itemId = `${doc.id}-${clientName}`;
        let item = this._store.find(x => x !== void 0 && x.itemId === itemId);
        if (item === void 0) {
          item = {
            id: doc.id,
            itemId: itemId,
            parentFolderID: doc.parentFolderID,
            name: doc.name,
            images: doc.images || [],
            clientName,
            qty: doc.savedProducts[clientName],
            isDisabled: false,
            folderName: await this.foldersTree.getFolderName(doc.parentFolderID)
          };
          if (item.images.length !== 0 && item.images[0].length !== 0) {
            item.imageURL = this.imageCache.getOrEmpty(item.images[0]);
          }
          this._store.push(item);
        }
        if (item.qty !== doc.savedProducts[clientName]) {
          item.qty = doc.savedProducts[clientName];
        }
        if (item.images[0] !== doc.images[0]) {
          item.images = doc.images;
          item.imageURL = this.imageCache.getOrEmpty(doc.images[0]);
        }
        if (item.name !== doc.name) {
          item.name = doc.name;
        }
        if (item.parentFolderID !== doc.parentFolderID) {
          item.parentFolderID = doc.parentFolderID;
          item.folderName = await this.foldersTree.getFolderName(doc.parentFolderID);
        }
      }
      //mark for remove
      const clientNames = Object.keys(doc.savedProducts).map(name => name.toLowerCase());
      for (let i = 0; i < this._store.length; i++) {
        if (this._store[i].id === doc.id && clientNames.indexOf(this._store[i].clientName.toLowerCase()) === -1) {
          this._store[i] = void 0;
        }
      }
    }
    //remove empty
    let index = this._store.findIndex(item => item === void 0);
    while (index !== -1) {
      this._store.splice(index, 1);
      index = this._store.findIndex(item => item === void 0);
    }

    this.aggregate();

    if (!snapshot.metadata.fromCache) {
      this.storeLoaded = true;
    }
  }

  private filterPredicate = (doc: IPreorderedDoc) => {
    if (this.term !== void 0) {
      if (this.type === 'name' && doc.name.toLowerCase().indexOf(this.term) === -1) {
        return false;
      }
      if (this.type === 'client' && doc.clientName.toLowerCase().indexOf(this.term) === -1) {
        return false;
      }
    }
    return true;
  }

  private async aggregate() {
    const store = this._store;
    const items = store.filter(this.filterPredicate);
    this.notify(items);
  }

  private callback?: (snapshot: IPreorderedSnapshotCallback) => void;
  private limit: number;
  private type?: 'name' | 'client';
  private term?: string;

  public subscribe(callback: (snapshot: IPreorderedSnapshotCallback) => void, limit: number, type?: 'name' | 'client', term?: string) {
    this.callback = callback;
    this.limit = limit;
    this.type = type;
    this.term = term;
    if (this.storeLoaded) {
      this.aggregate();
    }
  }

  public unsubscribe() {
    this.callback = void 0;
    this.limit = void 0;
    this.type = void 0;
    this.term = void 0;
  }

  private notify(items: Array<IPreorderedDoc>) {
    if (this.callback === void 0) {
      return;
    }
    const sorted = items.sort((a, b) => a.itemId.localeCompare(b.itemId));
    this.callback({ size: items.length, items: sorted.slice(0, this.limit) });
  }
}

export { PreorderedSnapshotService };
