import {Injectable, NgZone} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import firebase from 'firebase/app';
import {Router} from '@angular/router';
import {UserInfoService} from './user-info.service';
import {AuthDataInterface} from '../shared/interfaces/auth-data.interface';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import * as auth from 'firebase/app';
import {CompanyInterface} from '../shared/interfaces/company.interface';
import {SnackBarService} from '../shared/services/snack-bar.service';
import { DeviceUUID } from 'device-uuid';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthenticationService {

  userData: any; // Save logged in user data

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    private userInfoService: UserInfoService,
    private snackbarService: SnackBarService,
    private translateService: TranslateService,
    private db: AngularFirestore,
  ) {
    afAuth.authState.subscribe(_ => {
      const routesToNavigateFrom = ['/login', '/pass-recover'];
      const navigateToInventory = !this.userData && routesToNavigateFrom.findIndex(x => router.url.includes(x)) >= 0;
      this.afAuth.currentUser
        .then(user => {
          if (!user) {
            throw 'user is null';
          }
          this.userData = user;

          localStorage.setItem('userAuthInfo', JSON.stringify(this.userData));

          if (this.userData && this.userData.email) {
            //
            this.userInfoService.currentDeviceId = this.getDeviceId();
            this.userInfoService.loadUser(this.userData.email)
              .then(async (company: CompanyInterface) => {
                if (!company) return;
                company.devices[this.userData.email] = this.userInfoService.currentDeviceId;
                await this.afs.collection('Companies').doc(company.id.toString())
                  .set(company, { merge: true })
                  .then(res => {
                    //this.userInfoService.currentDeviceId = company.devices[this.userData.email];
                  })
                  .catch(error => {
                    console.error(error);
                  })
                  .finally(async () => {
                    this.translateService.use(this.userInfoService.user.language.toLowerCase());
                    await this.updateLastLoggedInData(company.id.toString())
                  });
              })
              .catch(error => {
                console.error(error);
                throw error;
              })
              .finally(() => {
                if (navigateToInventory || this.router.url === '/') {
                  // Place to convinient debug/develop required component on app-reload; ***
                  // this.router.navigate(['user', 'settings', 'export-settings']);

                  this.router.navigate(['user', 'inventory', 'Main']);
                }
              });
          }
        })
        .catch(ex => {
          console.error('currentUser ex =>', ex);
          const redirectToLogin = this.userData && this.userData.email;
          if (this.userData) {
            this.userInfoService.clearUserData();
            this.userInfoService.userLoggedOut.next(true);
          }
          this.userData = null;
          localStorage.setItem('userAuthInfo', null);
          if (redirectToLogin) {
            this.router.navigate(['login']);
          }
        })
    });
  }

  public async getIdToken() {
    const user = await this.afAuth.currentUser;
    return user.getIdToken();
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('userAuthInfo'));
    return user !== null && (user.emailVerified);
  }

  login(email, password) {
    // this.loadingUserData = true;
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then(async (loginResult) => {
        this.userData = loginResult.user;
        localStorage.setItem('userAuthInfo', JSON.stringify(this.userData));
        return loginResult;
      });

  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.login(this.userData.email, oldPassword).then(res => {
      return this.afAuth.currentUser.then(user => {
        return user.updatePassword(newPassword);
      });
    });
  }

  private getDeviceId() {
    // let uid: string = window.navigator.mimeTypes.length.toString();
    // uid += window.navigator.userAgent.replace(/\D+/g, '');
    // uid += window.navigator.plugins.length;
    // return uid;
    const uuid: string = new DeviceUUID({
      version: true,
      language: true,
      platform: true,
      os: true,
      pixelDepth: true,
      colorDepth: true,
      resolution: true,
      isAuthoritative: true,
      silkAccelerated: true,
      isKindleFire: true,
      isDesktop: true,
      isMobile: true,
      isTablet: true,
      isWindows: true,
      isLinux: true,
      isLinux64: true,
      isChromeOS: true,
      isMac: true,
      isiPad: true,
      isiPhone: true,
      isiPod: true,
      isAndroid: true,
      isSamsung: true,
      isSmartTV: true,
      isRaspberry: true,
      isBlackberry: true,
      isTouchScreen: true,
      isOpera: true,
      isIE: true,
      isEdge: true,
      isIECompatibilityMode: true,
      isSafari: true,
      isFirefox: true,
      isWebkit: true,
      isChrome: true,
      isKonqueror: true,
      isOmniWeb: true,
      isSeaMonkey: true,
      isFlock: true,
      isAmaya: true,
      isPhantomJS: true,
      isEpiphany: true,
      source: true,
      cpuCores: true
    }).get();
    console.log('UUID is', uuid);
    return uuid;
  }

  logOut(navigate: boolean = true): Promise<void> {
    return this.afAuth.signOut().then(() => {
      if (this.userData) {
        this.userInfoService.clearUserData();
        this.userInfoService.userLoggedOut.next(true);
      }
      this.userData = null;
      localStorage.removeItem('userAuthInfo');
      if (navigate)
        this.router.navigate(['login']);
    });
  }

  sendVerificationMail() {
    auth.default.auth().languageCode = localStorage.getItem('lang') ?? 'en';
    return this.afAuth.currentUser.then(u => {
      if (u) {
        return u.sendEmailVerification();
      } else {
        return new Promise((resolve, reject) => {
          return reject();
        });
      }
    });
  }

  verifyPasswordResetCode(code: string) {
    return this.afAuth.verifyPasswordResetCode(code);
  }

  confirmPasswordReset(actionCode: string, newPassword: string) {
    return this.afAuth.confirmPasswordReset(actionCode, newPassword);
  }

  recoverPassword(passwordResetEmail: string) {
    auth.default.auth().languageCode = localStorage.getItem('lang') ?? 'en';
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  applyActionCode(actionCode) {
    return this.afAuth.applyActionCode(actionCode);
  }

  async updateLastLoggedInData(companyId: string) {
    try {
      const doc = await this.db.firestore.collection('/Companies/').doc(companyId)
 
      await doc.set(
          {
            lastLoggedIn: {
              [this.userData.email]: firebase.firestore.FieldValue.serverTimestamp(),
            },
          },
          { merge: true } 
        );
    } catch (error) {
      console.error(error);
    }
  }
}
