import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { UserInterface } from '../shared/interfaces/user.interface';
import { UserPermissionsService } from './user-permissions.service';

type firebaseDataTypes = string | number | boolean;

@Injectable({ providedIn: 'root' })
class UserSnapshotService {

  private _user: UserInterface;

  private readonly _store: Array<{ observableFields: Array<string>; callback: (user: UserInterface, type: 'new' | 'modified' | 'removed') => void }>;
  userSnapshotSubscription: boolean;

  constructor(private db: AngularFirestore, private userPermissions: UserPermissionsService) {
    console.log('UserSnapshotService ctor');
    this._store = [];
  }

  public subscribe(observableFields: Array<string>, callback: (user: UserInterface, type: 'new' | 'modified' | 'removed') => void) {
    this._store.push({
      observableFields,
      callback
    });
  }

  public unsubscribe(callback: (user: UserInterface) => void) {
    const index = this._store.findIndex(item => item.callback === callback);
    if (index !== -1) {
      this._store.splice(index, 1);
    }
  }

  private arraysAreEqual(one: Array<firebaseDataTypes>, two: Array<firebaseDataTypes>) {
    const a = one || [], b = two || [];
    if (a.length !== b.length) {
      return false;
    }
    for (const x of a) {
      if (b.indexOf(x) === -1) {
        return false;
      }
    }
    for (const y of b) {
      if (a.indexOf(y) === -1) {
        return false;
      }
    }
    return true;
  }

  private objectsAreEqual(one: { [key: string]: firebaseDataTypes }, two: { [key: string]: firebaseDataTypes }) {
    const a = one || {}, b = two || {};
    for (const x in a) {
      if (a[x] !== b[x]) {
        return false;
      }
    }
    for (const y in b) {
      if (b[y] !== a[y]) {
        return false;
      }
    }
    return true;
  }

  private detectChanges(nextUser: UserInterface) {
    const store = this._store;
    for (const item of store) {
      if (nextUser === void 0) {
        item.callback(void 0, 'removed');
      }
      else if (this._user === void 0) {
        item.callback(nextUser, 'new');
      }
      else {
        let hasChanges = false;
        for (const field of item.observableFields) {
          if (Array.isArray(this._user[field])) {
            hasChanges = !this.arraysAreEqual(this._user[field], nextUser[field]);
          }
          else if (typeof this._user[field] === 'object') {
            hasChanges = !this.objectsAreEqual(this._user[field], nextUser[field]);
          }
          else {
            hasChanges = this._user[field] !== nextUser[field];
          }
          if (hasChanges) {
            break;
          }
        }
        if (hasChanges) {
          item.callback(nextUser, 'modified');
        }
      }
    }
  }

  private _listener: () => void;
  private _companyId: number;
  private _userEmail: string;

  public init(companyId: number, userEmail: string) {
    if (!this.userSnapshotSubscription) {
      this._companyId = companyId;
      this._userEmail = userEmail;
      this.subscribe(['hasDeletePermission', 'isLimitedUser'], (user) => this.userPermissions.onPermissionUpdate$.next(user));
      this._listener = this.db.collection<UserInterface>(`/Users`).doc(this._userEmail).ref.onSnapshot({
        includeMetadataChanges: true
      }, this.onSnapshot, ex => {
        console.error(ex);
      });
    }
    this.userSnapshotSubscription = true;
  }

  public destroy() {
    if (this._listener !== void 0) {
      console.log('** UserSnapshotService destroy');
      this._listener();
      this._store.length = 0;
      this._user = void 0;
      this.userSnapshotSubscription = false;
    }
  }

  private onSnapshot = async (snapshot: firebase.firestore.DocumentSnapshot<UserInterface>) => {
    const user = snapshot.exists ? snapshot.data() : void 0;
    this.detectChanges(user);
    this._user = user;
  }
}

export { UserSnapshotService };
