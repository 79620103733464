<div class="background" (click)="cancel()">
</div>

<i class="slider-close" (click)="cancel()"></i>

<div class="popup-wrapper">
  <div class="popup-content">
    <p class="message">
      {{ 'other.downgrade-msg' | translate:{currentPlan: planName} }}
    </p>
    <i class="downgrade"></i>
    
    <div class="buttons">
      <button class="yes" (click)="onConfirm.emit()">{{ 'buttons.YES' | translate }}</button>
      <button class="no" (click)="cancel()">{{ 'buttons.NO' | translate }}</button>
    </div>
    
    <p class="restore">{{ 'other.restore-instructions' | translate}} <span style="cursor: pointer" (click)="mailTo()">support@shelfit.tech</span></p>
  </div>
</div>
