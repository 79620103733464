import {Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../authentication.service';
import {SnackBarService} from '../../shared/services/snack-bar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'up-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss']
})
export class SetNewPasswordComponent implements OnInit {

  form: FormGroup;
  isLoading = false;
  showPass = false;
  showConfirmPass = false;
  userEmail = '';
  errorMessage: string = ''
  screenHeight = window.innerHeight;
  actionCode = null;
  workerSetup = false;

  @HostListener('window:resize', ['$event'])
  windowResize() {
    this.screenHeight = window.innerHeight;
  }

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]]
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.actionCode = params.oobCode;
      this.workerSetup = params.workerSetup;

      if (this.actionCode) {
        this.isLoading = true;
        this.authenticationService.verifyPasswordResetCode(this.actionCode)
          .then((email) => {
            this.userEmail = email;
            this.isLoading = false;
          }).catch( error => {
            this.isLoading = false;
            this.errorMessage = this.translateService.instant('verify-email-form.invalid-code');
            setTimeout(() => {
              const msg = this.translateService.instant('set-new-password-form.invalid-code');
              this.snackBarService.alert(msg, 'error', true);
            }, 100);
        });
      }

    });
  }



  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.markAsDirty();
      this.form.updateValueAndValidity();

      if (this.form.controls.newPassword.errors && this.form.controls.newPassword.errors.minlength) {
        const msg = this.translateService.instant('snackbar-messages.min-password-length');
        this.snackBarService.alert(msg, 'error', true);
      }
      return;
    }

    if (this.form.value.newPassword !== this.form.value.confirmPassword) {
      const msg = this.translateService.instant('set-new-password-form.passwords-not-match');
      this.snackBarService.alert(msg, 'error', true);
      return;
    }

    this.isLoading = true;

    this.authenticationService.confirmPasswordReset(this.actionCode, this.form.value.newPassword)
      .then((resp) => {
        const msg = this.translateService.instant(this.workerSetup ? 'set-new-password-form.password-setup-msg' : 'set-new-password-form.password-changed-msg');
        this.snackBarService.alert(msg, 'success', true);
        setTimeout(() => {
          this.isLoading = false;
          this.router.navigateByUrl('/login')
          .then(() => window.location.reload());
          }, 1000);
      })
      .catch( error => {
        this.isLoading = false;
        const msg = this.translateService.instant('set-new-password-form.confirmation-error');
        this.snackBarService.alert(msg, 'error', true);
      });
  }

}
