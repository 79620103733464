import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {SnackAlertInterface} from '../interfaces/snack-alert.interface';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  public notifications = new Subject<SnackAlertInterface>();

  alert(msg: string, type: 'success' | 'error' = 'success', center = false, timeout = 3000) {
    this.notifications.next({msg, type, center, timeout});
  }

  alertError(msg: string, center = false, timeout = 5000) {
    this.notifications.next({msg, type: 'error', center, timeout});
  }
}
