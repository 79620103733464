import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  constructor(private db: AngularFirestore, private storage: AngularFireStorage) { }

  pushFileToStorage(fileUpload: FileUpload, companyId: string) {
    fileUpload.url = `/${companyId}/images`;
    const filePath = `${fileUpload.url}/${fileUpload.name}`;
    return this.storage.upload(filePath, fileUpload.file, {
      cacheControl: 'max-age=31536000',
      // "custom" metadata:
      //customMetadata: {
      //  firebaseStorageDownloadTokens: 'E9CEF2B7-E061-47F2-9C67-FF4FD2371E90', // Can technically be anything you want
      //}
    }).then((res) => {
      return fileUpload.name;
    });
  }

  getPhotoPublicReference(name, companyId: string): Promise<string> {
    const storageRef = this.storage.ref(`/${companyId}/images`);
    return storageRef.child(name).getDownloadURL().toPromise();
  }

  deleteFile(fileName: string, companyId) {
    const storageRef = this.storage.ref(`/${companyId}/images`);
    return storageRef.child(fileName).delete().toPromise();
  }

  createPersistentDownloadUrl(pathToFile, downloadToken) {
    return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/${encodeURIComponent(
      pathToFile
    )}?alt=media&token=${downloadToken}`;
  };
}


export class FileUpload {
  name: string;
  url: string;
  file: File;

  constructor(file: File, userId, nameModifier?: string, useNativeFileName?: boolean) {
    this.file = file;
    this.name = useNativeFileName ? file.name :
      `${new Date().getTime()}${nameModifier || ''}_${userId}`;
  }
}
