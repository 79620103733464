import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FolderInterface } from '../shared/interfaces/folder.interface';
import firebase from 'firebase/app';

class FolderChangeEvent {

  private readonly _events: Array<(newFolder: FolderInterface, type: 'removed' | 'modified') => void>;

  constructor(public folderId: string) {
    this._events = [];
  }

  public subscribe(onChange: (newFolder: FolderInterface, type: 'removed' | 'modified') => void) {
    const index = this._events.findIndex(e => e === onChange);
    if (index === -1) {
      console.log('FolderChangeEvent subscribe', this.folderId);
      this._events.push(onChange);
    }
  }

  public unsubscribe(onChange: (newFolder: FolderInterface, type: 'removed' | 'modified') => void) {
    const index = this._events.findIndex(e => e === onChange);
    if (index !== -1) {
      console.log('FolderChangeEvent unsubscribe ', this.folderId);
      this._events.splice(index, 1);
    }
  }

  public notify(newFolder: FolderInterface, type: 'removed' | 'modified') {
    if (this.folderId === newFolder.id) {
      for (const e of this._events) {
        if (e !== void 0) {
          e(newFolder, type);
        }
      }
    }
  }
}

@Injectable({ providedIn: 'root' })
class FoldersTree {

  private readonly _store: Array<FolderInterface>;
  private _storeLoaded: boolean;

  private readonly _onChangeEvents: Map<string, FolderChangeEvent>;

  constructor(private db: AngularFirestore) {
    console.log('FoldersTree ctor');
    this._store = [];
    this._storeLoaded = false;

    this._onChangeEvents = new Map<string, FolderChangeEvent>();
  }

  private unsubscribe: () => void;
  private companyId: number;

  public init(companyId: number) {
    console.log('FoldersTree init');
    this.companyId = companyId;
    if (this.unsubscribe !== void 0) {
      this.unsubscribe();
    }
    this.unsubscribe = this.db.collection<FolderInterface>(`/Companies/${this.companyId}/Inventory`).ref.where('type', '==', 'Folder').onSnapshot({ includeMetadataChanges: true }, this.onSnapshot, ex => { console.error(ex); });
  }

  public destroy() {
    console.log('FoldersTree destroy');
    if (this.unsubscribe !== void 0) {
      this.unsubscribe();
      this._store.length = 0;
    }
    this._onChangeEvents.clear();
  }

  private onSnapshot = (snapshot: firebase.firestore.QuerySnapshot<FolderInterface>) => {
    const changes = snapshot.docChanges();
    for (const change of changes) {
      const index = this._store.findIndex(x => x.id === change.doc.id);
      const folder = change.doc.data();
      if (change.type === 'removed') {
        if (index !== -1) {
          this._store.splice(index, 1);
          this.notifyChangeFolder(folder, 'removed');
        }
      }
      else { //modified or added
        if (index === -1) {
          this._store.push(folder);
        }
        else {
          this._store[index] = folder;
        }
        this.notifyChangeFolder(folder, 'modified');
      }
    }
    if (!snapshot.metadata.fromCache) {
      this._storeLoaded = true;
    }
  }

  private async getFolderFromDb(id: string) {
    const ref = this.db.collection<FolderInterface>(`/Companies/${this.companyId.toString()}/Inventory`).doc(id).ref;
    const item = await ref.get();
    return item.exists ? item.data() : void 0;
  }

  private async getFoldersByParentIdFromDb(parentId: string) {
    const query = this.db.collection<FolderInterface>(`/Companies/${this.companyId}/Inventory`).ref.where('type', '==', 'Folder').where('parentFolderID', '==', parentId);
    const items = await query.get();
    return items.docs.map(doc => doc.data());
  }

  public async getFolderById(id: string) {
    if (this._storeLoaded) {
      let folder = this._store.find(folder => folder.id === id);
      if (id !== 'Main' && folder === void 0) {
        folder = await this.getFolderFromDb(id);
      }
      return folder;
    }
    else {
      return await this.getFolderFromDb(id);
    }
  }

  public async getFoldersByParentId(parentId: string, foldersIDAccess: Array<string> = ['0']) {
    let folders: Array<FolderInterface> = [];
    if (this._storeLoaded) {
      for (const folder of this._store) {
        if (folder.parentFolderID === parentId) {
          folders.push(folder);
        }
      }
    }
    else {
      console.log('getFoldersByParentId');
      folders = await this.getFoldersByParentIdFromDb(parentId);
    }
    folders = folders.sort((a, b) => a.name.localeCompare(b.name));
    if (foldersIDAccess.indexOf('0') !== -1) {
      return folders;
    }
    else if (parentId === 'Main') {
      return folders.filter(folder => foldersIDAccess.indexOf(folder.id) !== -1);
    }
    else {
      return folders.filter(folder => foldersIDAccess.indexOf(folder.rootFolderId) !== -1);
    }
  }

  public async getFolderName(id: string) {
    if (id === 'Main') {
      return 'Main';
    }
    const folder = await this.getFolderById(id);
    return folder !== void 0 ? folder.name : 'Main';
  }

  public subscribeToChangeFolder(folderId: string, onChange: (newFolder: FolderInterface, type: 'removed' | 'modified') => void) {
    const event = this._onChangeEvents.get(folderId) || this._onChangeEvents.set(folderId, new FolderChangeEvent(folderId)).get(folderId);
    event.subscribe(onChange);
    return () => {
      event.unsubscribe(onChange);
    };
  }

  private notifyChangeFolder(newFolder: FolderInterface, type: 'removed' | 'modified') {
    const event = this._onChangeEvents.get(newFolder.id);
    if (event !== void 0) {
        event.notify(newFolder, type);
    }
  }
}

export { FoldersTree, FolderChangeEvent }
