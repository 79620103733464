// 1 for Free plan
// 2 for Advanced plan
// 3 for Premium plan
// price in $

export enum COMPANY_PLAN_TYPE{
  BASIC = 1,
  ADVANCED = 2,
  PREMIUM = 3,
}

export const PLAN_PRICE = { // in dollars
  1: 0,
  2: 29,
  3: 69
};

export const PLAN_PRICES = { // in dollars
  1: {
    monthly: 0
  },
  2: {
    monthly: 29,
    yearly: 278
  },
  3: {
    monthly: 69,
    yearly: 662
  }
};

export const ITEMS_LIMIT = {
  1: 30,
  2: 2500,
  3: Infinity
};

export const FREE_USERS_LIMIT = {
  1: 1,
  2: 4,
  3: 6
};

export const USER_PRICE = {
  1: 3,
  2: 3,
  3: 3
};

export const NOTIFICATIONS_PERMISSION = {
  1: false,
  2: true,
  3: true
};

export const ACTIVITY_LOGS = {
  1: 0,
  2: 10,
  3: 20
};

export const PHOTOS_PER_ITEM_LIMIT = {
  1: 2,
  2: 8,
  3: 10
};

export const LABELS_LIMIT = {
  1: 2,
  2: 10,
  3: 30
};

export const ADDITIONAL_FIELDS_LIMIT = {
  1: 2,
  2: 10,
  3: 30
};

export const REPORT_PERMISSION = {
  1: false,
  2: true,
  3: true
};

export const IMPORT_PERMISSION = {
  1: false,
  2: true,
  3: true
};

export const EXPORT_PERMISSION = {
  1: false,
  2: true,
  3: true
};

export const COMPANY_LOGO = {
  1: false,
  2: true,
  3: true
};

export const CUSTOM_BARCODE = {
  1: false,
  2: true,
  3: true
};
