import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CommonService} from '../../services/common.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'up-side-popover-screen',
  templateUrl: './side-popover-screen.component.html',
  styleUrls: ['side-popover-screen.component.scss'],
  animations: [
    trigger('windowTint', [
      state('open', style({
        opacity: '39%'
      })),
      state('closed', style({
        opacity: '0%'
      })),
      transition('* => *', animate('300ms ease-out'))
    ])
  ]
})
export class SidePopoverScreenComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() confirmModalCancelBtnText = 'Cancel';
  @Input() confirmModalConfirmBtnText = 'Discard';
  @Input() zIndex;
  @Input() closeBtn = true;
  @Input() ignoreChanges = false;
  @Output() onClose = new EventEmitter();

  @ViewChild('sidePageWrapper') sidePageWrapper: ElementRef;
  @ViewChild('backgroundFade') backgroundFade: ElementRef;

  windowState: 'closed' | 'open' = 'closed';
  showConfirmModal = false;
  emitConfirmationResult = false;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.closeBtn && event.keyCode === 27) {
      this.closeWithConfirmModal();
    }
  }

  @HostListener('window:resize', ['$event'])
  windowResize() {
    setTimeout(() => {
      const htmlWrapper: any = document.querySelector('#sidePageWrapper');
      htmlWrapper.style.height = document.querySelector('#global-content').clientHeight + 'px';
    });
  }

  constructor(
    private commonService: CommonService,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.commonService.confirm.subscribe(() => {
      this.showConfirmModalAndEmitResult();
    });
  }

  ngAfterViewInit() {
    this.windowState = 'open';
    this.cdRef.detectChanges();
    const htmlWrapper: any = document.querySelector('#sidePageWrapper');
    htmlWrapper.style.height = document.querySelector('#global-content').clientHeight + 'px';
  }


  closeWithConfirmModal() {
    if (this.ignoreChanges) {
      this.closeModal();
      return;
    }
    if (this.commonService.editingForm && this.commonService.editingForm.dirty) {
      this.showConfirmModal = true;
    } else {
      this.closeModal();
    }
  }

  showConfirmModalAndEmitResult() {
    this.showConfirmModal = true;
    this.emitConfirmationResult = true;
  }

  closeModal(bool?) {
    this.windowState = 'closed';
    setTimeout(() => {
      this.onClose.emit(bool);
      this.emitConfirmationResult = false;
      if (!this.ignoreChanges) {
        this.commonService.onConfirmationClosed.next(true);
      }
    }, 300);
  }

  ngOnDestroy(): void {
    if (!this.ignoreChanges) {
      this.commonService.editingForm = null;
    }
  }

  get confirmMsg() {
    if (!this.commonService.editingForm) {
      return;
    }

    const control = this.commonService.editingForm.get('name') || this.commonService.editingForm.get('name');
    const isFolder = !!this.commonService.editingForm.get('name');

    if (control.value) {
      return this.translateService.instant('confirm-messages.unsaved', {name: control.value});
    } else {
      return isFolder
        ? this.translateService.instant('confirm-messages.unsaved-folder')
        : this.translateService.instant('confirm-messages.unsaved-item');
    }
  }

  get sidePageWidth() {
    return this.sidePageWrapper.nativeElement.offsetWidth;
  }

  closeConfirmation() {
    this.showConfirmModal = false;
    this.commonService.onConfirmationClosed.next(false);
  }
}
