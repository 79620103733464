<img src="../../../assets/images/login-background.svg" class="background" [ngStyle]="{'height': screenHeight + 'px'}">

<div class="form-wrapper" [ngStyle]="{'height': screenHeight + 'px'}">
  <form>
    <div class="header">
      <img class="logo" src="assets/logos/shelfit.svg" alt="logo">
    </div>

    <p class="title d-flex justify-content-center">
      <span>
        {{ 'privacy-policy.hi' | translate }} {{userName}},<br />
        {{ 'privacy-policy.welcome-to-shelfit' | translate }}
      </span>
    </p>

    <div class="footer">
      <div class="checkbox">
        <input
          (change)="togglePolicy()"
          id="remember"
          type="checkbox">
        <label for="remember" class="box"></label>
        <label for="remember">
          {{ 'privacy-policy.agree' | translate }}
          <a href="javascript:;">{{ 'privacy-policy.terms' | translate }}</a>
          {{ 'privacy-policy.and' | translate  }}
          <a href="javascript:;">{{ 'privacy-policy.policy' | translate  }}</a>
        </label>
      </div>
    </div>

    <button
      class="submit-btn"
      [disabled]="!acceptedPolicy"
      (click)="onSubmitClick($event)"
    >
      {{ 'privacy-policy.button' | translate }}
    </button>

  </form>
</div>
