<img src="../../../assets/images/login-background.svg" class="background" [ngStyle]="{'height': screenHeight + 'px'}">

<div class="form-wrapper" [ngStyle]="{'height': screenHeight + 'px'}">
  <div class="form" *ngIf="emailVerified">
    <div class="header">
      <img class="logo" src="assets/logos/shelfit.svg" alt="logo">
    </div>

    <p class="message">{{ 'verify-email-form.email-was-verified' | translate}}</p>

    <button class="submit-btn" (click)="openPanel()" [disabled]="isLoading">
      {{ 'verify-email-form.userpanel'  | translate}}
      <i class="userpanel"></i>
    </button>
  </div>

  <div class="form" *ngIf="errorMessage.length">
    <div class="header">
      <img class="logo" src="assets/logos/shelfit.svg" alt="logo">
    </div>
    <p class="message">{{errorMessage}}</p>
  </div>

</div>



