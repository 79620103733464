import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {UserInterface} from '../shared/interfaces/user.interface';
import {AngularFirestore} from '@angular/fire/firestore';
import {CompanyInterface} from '../shared/interfaces/company.interface';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireFunctions} from '@angular/fire/functions';
import {ImageUploadService} from '../shared/services/image-upload.service';
import {SnackBarService} from '../shared/services/snack-bar.service';
import * as DeviceUUID from 'device-uuid';
import firebase from 'firebase/app';
import {TranslateService} from '@ngx-translate/core';
import { FirebaseStoreService } from './FirebaseStoreService';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  public user: UserInterface;
  public currentDeviceId = '';
  public loggedUser: BehaviorSubject<UserInterface> = new BehaviorSubject<UserInterface>({} as UserInterface);
  company: CompanyInterface;
  public companyLogoUrl = '';
  companyChanged: BehaviorSubject<CompanyInterface> = new BehaviorSubject<CompanyInterface>({} as CompanyInterface);
  public userLoggedOut = new Subject<boolean>();
  public afSubscriptions = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private db: AngularFirestore,
    private auth: AngularFireAuth,
    private func: AngularFireFunctions,
    private imageUploadService: ImageUploadService,
    private afAuth: AngularFireAuth,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private storeService: FirebaseStoreService
  )
  {
  }

  private setCookie = (name,value,days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  loadUser(id?: string, loadCompany: boolean = true) {
    if (!id && this.user.email) {
      id = this.user.email;
    }
    console.log('loadUser dashboardAccess id', id);

    const docref = this.db.collection<UserInterface>('/Users').doc(id);
    return docref.ref.get().then((doc) => {
      const user = doc.data();
      console.log('loadUser dashboardAccess ', user);
      if (user) {
        if (!this.user && user) {
          console.log(user);
        }
        this.user = user;
        this.translateService.use(user.language.toLowerCase());

        this.loggedUser.next(this.user);

        if (user && !user.dashboardAccess) {
          this.snackBarService.alertError(this.translateService.instant('snackbar-messages.no-dashboard-access'), true);

          //this.router.navigate(['login']);
          return new Promise(((resolve, reject) => reject()));
        }

        return loadCompany ? this.loadCompany(this.user.companyID.toString()) : user;
      }
    });
  }

  async loadCompany(id?: string) {
    if (!id && this.company) {
      id = this.company.id.toString();
    }
    const docref = this.db.collection<CompanyInterface>('/Companies').doc(id);
    return docref.ref.get().then(async (doc) => {
      const company = doc.data();
      if (!this.company && company) {
        console.log(company);
      }

      if (this.company && this.company.id && company && company.devices && company.devices[this.user.email]
        && this.currentDeviceId && company.devices[this.user.email] !== this.currentDeviceId) {
        console.log('logout!');

        this.snackBarService.alertError(this.translateService.instant('other.another-device-used'));
         setTimeout(() => {
           this.logOut();
         }, 2000);

        return null;
      }
      this.company = company as CompanyInterface;
      await this.loadCompanyLogo();
      this.companyChanged.next(this.company);
      return this.company;
    }
    );
  }

  async loadCompanyLogo() {
    if (this.company.logo && !this.companyLogoUrl.includes(this.company.logo)) {
      this.companyLogoUrl = await (this.imageUploadService.getPhotoPublicReference(this.company.logo, this.company.id.toString()).catch(error => null)) || '';
    }

    if (!this.company.plan.isLogo) {
      this.companyLogoUrl = '';
    }
  }
  /*
  removeCompany() {
    const companyId = this.company.id;
    const user = this.user.email;

    const deleteFn = this.func.httpsCallable('removeCompany');
    return deleteFn({ companyId}).toPromise()
      .then((result) => {
        this.companyChanged.next(null);
        this.loggedUser.next(null);
        return result;
      })
      .catch((err) => {
        console.error('Delete failed, see console,');
      });
  }
  */

  public removeCompany() {
    return this.storeService.removeCompany(this.company.id, this.user.email);
  }

  clearUserData() {
    this.company = null;
    this.user = null;
    this.companyChanged.next(null);
    this.loggedUser.next(null);
    this.setCookie('rememberUser', false, -1);
  }

  updateCompany(payload: CompanyInterface) {
    return this.db.collection('/Companies').doc(payload.id.toString()).set(payload, {merge: true});
  }

  addCompanyAdditionalUsers(additionalUsers: number) {
    const companyToUpdate: any = {
      id: this.company.id,
      additionalUsersPending: additionalUsers
    };
    return this.updateCompany(companyToUpdate)
      .then(async (res) => {
        await this.updateCurrentCompanyMainInfo(companyToUpdate);
        return res;
      });
  }

  setUserLanguage(userLanguage) {
    const language = userLanguage.charAt(0).toUpperCase() + userLanguage.substring(1);
    if (!this.user.email) {
      return;
    }
    return this.db.collection('/Users').doc(this.user.email).set({language}, {merge: true});
  }

  logOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('userAuthInfo');
      this.router.navigate(['login']);
    });
  }

  getUsersInventoryRootPath() {
    return `/Companies/${this.company.id}/Inventory`;
  }

  updateCurrentUserMainInfo(userInfo: UserInterface) {
    Object.assign(this.user, userInfo);
    this.loggedUser.next(this.user);
  }

  async updateCurrentCompanyMainInfo(company: CompanyInterface) {
    Object.assign(this.company, company);

    await this.loadCompanyLogo();
    this.companyChanged.next(this.company);
  }

  public getUserByEmail(email: string) {
    const docref = this.db.collection('/Users').doc(email);
    return docref.ref.get()
      .then((doc) => {
        return <UserInterface>doc.data();
      })
      .catch((ex: any) => {
        return <UserInterface>null;
      })
  }

  public isCompanySubscriptionDateExpired(id: number) {
    const docref = this.db.collection<CompanyInterface>('/Companies').doc(id.toString());
    return docref.ref.get().then(async (doc) => {
      const company = doc.data();

      if (company?.plan?.subsExpireDate === 'unlimited') {
        return null;
      }

      if (company?.plan?.planName != 'Basic' && company?.plan?.subsExpireDate) {
        var expTimestamp = Number.parseInt(company.plan.subsExpireDate);
        const expDate = moment.unix(expTimestamp);
        const today = moment();
        const diffResult = expDate.diff(today,'seconds');
        return diffResult < 0
      }
    });
  }

  public setShowBasicPlanMsgFlag(showBasicPlanMsg: boolean, email: string) {
    return this.db.collection('/Users').doc(email).set({ showBasicPlanMsg }, { merge: true });
  }

  public setUserPrivacyPolicy(isPrivacyPolicyConfirmed: boolean, email: string) {
    return this.db.collection('/Users').doc(email).set({ isPrivacyPolicyConfirmed }, { merge: true });
  }
}
