import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'up-downgrade-popup',
  templateUrl: 'downgrade-popup.component.html',
  styleUrls: ['downgrade-popup.component.scss']
})
export class DowngradePopupComponent implements OnInit {

  @Input() planId: number;
  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  planName: string;

  constructor(private translateService: TranslateService) {
  }

  cancel() {
    this.onCancel.emit();
  }

  ngOnInit(): void {
    if (this.planId) {
      this.planName = this.translateService.instant(`other.company-plan.${this.planId}`);
    }
  }

  mailTo() {
    window.open('mailto:support@shelfit.tech', '_blank');
  }
}
