<div class="modal-wrapper" [ngClass]="{'fixed': fixedPosition}"
     [ngStyle]="{
       'padding-right': paddingRight + 'px',
       'padding-left': paddingLeft + 'px'
     }">
  <div class="confirm-modal" [class.is-open]="modalState === 'open'">
    <p style="word-break: break-word">{{ msg }}</p>
    <div class="spinner-container" *ngIf="showLoading">
      <div class="up-spinner"></div>
    </div>
    <div class="buttons-container" *ngIf="!showLoading">
      <button class="transparent-btn slim" (click)="emitCancel()" *ngIf="showCancelButton">{{ cancelBtnText }}</button>
      <button class="transparent-btn slim" (click)="emitConfirm()">{{ confirmBtnText }}</button>
    </div>
  </div>
</div>
