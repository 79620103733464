import {NgModule} from '@angular/core';
import {SidePopoverScreenComponent} from './components/side-popover-screen/side-popover-screen.component';
import {QrCodeComponent} from './components/qr-code/qr-code.component';
import {PhotoUploaderComponent} from './components/photo-uploader/photo-uploader.component';
import {CommonModule} from '@angular/common';
import {NumberInputComponent} from './components/number-input/number-input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserIconComponent} from './components/user-icon/user-icon.component';
import {TranslateModule} from '@ngx-translate/core';
import {TagsSelectorComponent} from './components/tags-selector/tags-selector.component';
import {NgxQRCodeModule} from 'ngx-qrcode2';
import {ConfirmModalComponent} from './components/confirm-modal/confirm-modal.component';
import {MsgSnackComponent} from '../core/msg-snack/msg-snack.component';
import {ChangePlanComponent} from './components/change-plan/change-plan.component';
import {AddItemSideBarComponent} from './components/add-item-side-bar/add-item-side-bar.component';
import {AdditionalFieldsInputsComponent} from './components/additional-fields-inputs/additional-fields-inputs.component';
// import {NgxDatePickerModule} from '@ngx-tiny/date-picker';
import {ActionButtonsComponent} from './components/action-buttons/action-buttons.component';
import {SearchSideBarComponent} from './components/search-side-bar/search-side-bar.component';
import {SortOrderSideBarComponent} from './components/sort-order-side-bar/sort-order-side-bar.component';
import {RouterModule} from '@angular/router';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {PaymentChargeComponent} from '../modules/settings/components/payment-charge/payment-charge.component';
import {WorkersNumberComponent} from '../modules/settings/components/workers-number/workers-number.component';
import {DowngradePopupComponent} from '../modules/settings/components/downgrade-popup/downgrade-popup.component';
import {UserManagementComponent} from '../modules/settings/components/user-management/user-management.component';
import {UserFormComponent} from '../modules/settings/components/user-form/user-form.component';
import {DirectoryComponent} from '../modules/inventory/components/directory/directory.component';
import {ItemComponent} from '../modules/inventory/components/item/item.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {NgxMaskModule} from 'ngx-mask';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {SearchPreorderedComponent} from "./components/search-preordered-side-bar/search-preordered.component";
import { MultipleTabsOpenedWarnComponent } from '../core/multiple-tabs-opened-warn/multiple-tabs-opened-warn.component';
import { UpgradeSectionComponent } from './components/upgrade-section/upgrade-section.component';
import { ContactUsSectionComponent } from './components/contact-us/contact-us-section.component';

@NgModule({
  declarations: [
    SidePopoverScreenComponent,
    QrCodeComponent,
    PhotoUploaderComponent,
    MsgSnackComponent,
    NumberInputComponent,
    UserIconComponent,
    TagsSelectorComponent,
    ConfirmModalComponent,
    MultipleTabsOpenedWarnComponent,
    DirectoryComponent,
    ItemComponent,
    ChangePlanComponent,
    AddItemSideBarComponent,
    AdditionalFieldsInputsComponent,

    PaymentChargeComponent,
    WorkersNumberComponent,
    DowngradePopupComponent,
    UserManagementComponent,
    UserFormComponent,
    ActionButtonsComponent,
    SearchSideBarComponent,
    SearchPreorderedComponent,
    SortOrderSideBarComponent,
    UpgradeSectionComponent,
    ContactUsSectionComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        NgxQRCodeModule,
        RouterModule,
        ReactiveFormsModule,
        // NgxDatePickerModule,
        NgbTooltipModule,
        BsDatepickerModule,
        NgxMaskModule,
        ScrollingModule
    ],
  exports: [
    SidePopoverScreenComponent,
    QrCodeComponent,
    PhotoUploaderComponent,
    MsgSnackComponent,
    NumberInputComponent,
    UserIconComponent,
    TagsSelectorComponent,
    ConfirmModalComponent,
    MultipleTabsOpenedWarnComponent,
    DirectoryComponent,
    ChangePlanComponent,
    ItemComponent,
    AddItemSideBarComponent,
    AdditionalFieldsInputsComponent,

    PaymentChargeComponent,
    WorkersNumberComponent,
    UserManagementComponent,
    DowngradePopupComponent,
    UserFormComponent,
    ActionButtonsComponent,
    SearchSideBarComponent,
    SortOrderSideBarComponent,
    SearchPreorderedComponent,
    UpgradeSectionComponent,
    ContactUsSectionComponent
  ]
})
export class SharedModule {
}

