import {Component, HostListener, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute, Router} from "@angular/router";
import {UserInfoService} from "../user-info.service";
import { AuthenticationService } from '../authentication.service';
import { UserInterface } from '../../shared/interfaces/user.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'up-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('out', style({
        transform: 'translateY(-100%)',
        opacity: '0'
      })),
      state('in', style({
        transform: 'translateY(-50%)',
        opacity: '1'
      })),
      transition('* => *', animate('300ms ease-out'))
    ])
  ]
})
export class PrivacyPolicyComponent implements OnInit {
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  public acceptedPolicy: boolean = false;
  public userName: string = ''

  @HostListener('window:resize', ['$event'])
  windowResize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private userInfoService: UserInfoService
  ) {
    this.setUserName(this.userInfoService.user);
  }

  private setUserName(user: UserInterface) {
    if (user !== void 0 && user !== null) {
      this.userName = `${user.firstName} ${user.lastName}`.trim();
    }
  }

  public ngOnInit(): void {
    this.userInfoService.getUserByEmail(this.authenticationService.userData.email).then(user => {
      this.setUserName(user);
    });
  }

  public togglePolicy() {
    this.acceptedPolicy = !this.acceptedPolicy;
  }

  public onSubmitClick(e: Event) {
    if (e !== void 0) {
      e.preventDefault();
    }
    this.userInfoService.setUserPrivacyPolicy(true, this.authenticationService.userData.email).then(_ => {
      if (this.userInfoService.user !== void 0 && this.userInfoService.user !== null) {
        this.userInfoService.user.isPrivacyPolicyConfirmed = true;
      }
      this.router.navigate(['/user', 'inventory', 'Main']);
    });
  }
}
