<div class="up-upgrade">
  <div>{{ 'upgrade-section.upgrade' | translate }}</div>
  <div class="modal-buttons">
    <contact-us-section
    (openContactModal)="onOpenContactModal($event)"
    ></contact-us-section>
    <a class="up-btn up-btn-primary up-app-button" [href]="appLink">
      <img src="assets/images/play_prism_512dp.svg">
      <div class="btn-content">
        <span class="btn-sub-title">{{ 'upgrade-section.using-app' | translate }}</span>
        <span class="btn-title">{{ 'upgrade-section.google-play' | translate }}</span>
      </div>
    </a>
  </div>
</div>
