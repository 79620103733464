import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'up-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['confirm-modal.component.scss'],
  // animations: [
  //   trigger('modalState', [
  //     state('closed', style({
  //       transform: 'translateY(100%)'
  //     })),
  //     state('open', style({
  //       transform: 'translateY(0%)'
  //     })),
  //     transition('* => *', animate('300ms ease-out'))
  //   ])
  // ]
})
export class ConfirmModalComponent implements OnInit {

  @Input() msg = 'confirm';
  @Input() action;
  @Input() pr = 0;
  @Input() cancelBtnText = this.translateService.instant('buttons.cancel');
  @Input() confirmBtnText = this.translateService.instant('buttons.discard');
  @Input() showLoading = false;
  @Input() closeOnConfirm = true;
  @Input() showCancelButton = true;
  @Input() fixedPosition = false;

  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();

  constructor(private translateService: TranslateService) {
  }

  modalState = 'closed';

  ngOnInit(): void {
    setTimeout(() => {
      this.modalState = 'open';
    }, 0);
  }

  emitCancel() {
    this.modalState = 'closed';
    setTimeout(() => this.cancel.emit(), 300);
  }

  emitConfirm() {
    if (this.closeOnConfirm) {
      this.modalState = 'closed';
    }
    this.confirm.emit(this.action);
  }

  closeModal(callback?: any) {
    this.modalState = 'closed';
    if (callback) {
      setTimeout(() => callback(), 300);
    }
  }

  get paddingRight() {
    return this.translateService.currentLang === 'he' ? 0 : this.pr;
  }

  get paddingLeft() {
    return this.translateService.currentLang === 'he' ? this.pr : 0;
  }
}

