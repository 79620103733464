import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { INotificationsSnapshotCallback } from '../shared/interfaces/INotificationsSnapshotCallback';
import { ISort } from '../shared/interfaces/ISort';
import { NotificationInterface } from '../shared/interfaces/notification.interface';
import { FirebaseLocalImageCache } from './FirebaseLocalImageCache';
import { FoldersTree } from './FoldersTreeService';

@Injectable({ providedIn: 'root' })
class NotificationsSnapshotService {

  constructor(private db: AngularFirestore, private imageCache: FirebaseLocalImageCache, private foldersTree: FoldersTree) {
    this.inited = false;
    this._store = [];
    this.storeLoaded = false;
  }

  private inited: boolean;
  private readonly _store: Array<NotificationInterface>;
  private storeLoaded: boolean;

  private listener: () => void;
  private companyId: number;
  private foldersIDAccess: Array<string>;
  private hasRootAccess: boolean;

  private callback?: (snapshot: INotificationsSnapshotCallback) => void;
  private limit: number;
  private sort: ISort;

  public init(companyId: number, foldersIDAccess: Array<string> = ['0']) {
    if (this.inited) {
      return;
    }
    this.inited = true;
    this.companyId = companyId;
    this.foldersIDAccess = foldersIDAccess;
    this.hasRootAccess = foldersIDAccess.indexOf('0') !== -1;

    this.listener = this.db.collection<NotificationInterface>(`/Companies/${this.companyId}/Notifications`).ref.onSnapshot({ includeMetadataChanges: true }, this.onSnapshot, ex => console.error(ex));
  }

  public destroy() {
    console.log('NotificationsSnapshotService destroy');
    this.unsubscribe();
    if (this.listener !== void 0) {
      this.listener();
    }
    this.inited = false;
    this._store.length = 0;
    this.storeLoaded = false;
  }

  private onSnapshot = async (snapshot: firebase.firestore.QuerySnapshot<NotificationInterface>) => {
    const changes = snapshot.docChanges();
    console.log('NotificationsSnapshotService', changes.length, snapshot.metadata.fromCache, snapshot.size);
    for (const change of changes) {
      const doc = change.doc.data();
      if (!this.hasRootAccess) {
        if (doc.parentFolderID === 'Main') {
          if (this.foldersIDAccess.indexOf(doc.id) === -1) {
            continue;
          }
        }
        else if (this.foldersIDAccess.indexOf(doc.rootFolderId) === -1) {
          continue;
        }
      }
      let index = this._store.findIndex(item => item.id === doc.id);
      if (change.type === 'removed') {
        if (index !== -1) {
          this._store.splice(index, 1);
        }
      }
      else {
        if (index === -1) {
          index = this._store.push(doc) - 1;
        }
        else {
          this._store[index] = doc;
        }
      }
    }

    if (!snapshot.metadata.fromCache) {
      this.storeLoaded = true;
    }

    if (this.callback !== void 0) {
      this.aggregate();
    }
  }

  public subscribe(callback: (snapshot: INotificationsSnapshotCallback) => void, sort: ISort, limit: number) {
    this.callback = callback;
    this.sort = sort;
    this.limit = limit;
    if (this.storeLoaded) {
      this.aggregate();
    }
  }

  public unsubscribe() {
    this.callback = void 0;
    this.limit = void 0;
    this.sort = void 0;
  }

  private aggregate() {
    const items = this._store;
    this.notify(items);
  }

  private notify(items: Array<NotificationInterface>) {
    if (this.callback === void 0) {
      return;
    }
    let sorted: Array<NotificationInterface>;
    switch (this.sort.key) {
      case 'name':
        if (this.sort.order === 'asc') {
          sorted = items.sort((a, b) => a.name.localeCompare(b.name));
        }
        else {
          sorted = items.sort((a, b) => b.name.localeCompare(a.name));
        }
        break;
      case 'quantity':
        if (this.sort.order === 'asc') {
          sorted = items.sort((a, b) => a.quantity - b.quantity);
        }
        else {
          sorted = items.sort((a, b) => b.quantity - a.quantity);
        }
        break;
      default:
        sorted = items.sort((a, b) => a.name.localeCompare(b.name));
        break;
    }
    this.callback({ size: items.length, items: sorted.slice(0, this.limit) });
  }
}

export { NotificationsSnapshotService };
