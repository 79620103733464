import {Component, OnInit} from '@angular/core';
import {SnackBarService} from '../../shared/services/snack-bar.service';
import {SnackAlertInterface} from '../../shared/interfaces/snack-alert.interface';

@Component({
  selector: 'up-msg-snack',
  templateUrl: './msg-snack.component.html',
  styleUrls: ['msg-snack.component.scss']
})
export class MsgSnackComponent implements OnInit {

  snackbarMsg: SnackAlertInterface = null;
  isClosed = true;
  timOut;
  inited = false;

  constructor(private snackBarService: SnackBarService) {
  }

  ngOnInit(): void {
    this.snackBarService.notifications.asObservable().subscribe(alert => {
      if (this.timOut) {
        clearTimeout(this.timOut);
        this.close();
      }

      this.snackbarMsg = alert;
      this.isClosed = false;
      this.timOut = setTimeout(() => {
        this.close();
      }, alert.timeout || 3000);
    });
    this.inited = true;
  }

  close() {
    this.isClosed = true;
  }
}
