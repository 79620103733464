import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../authentication.service';
import {SnackBarService} from '../../shared/services/snack-bar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {TranslateService} from '@ngx-translate/core';
import {UserInfoService} from '../user-info.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { FirebaseStoreService } from '../FirebaseStoreService';
import { InventoryService } from '../../modules/inventory/services/inventory.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'up-login',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('out', style({
        transform: 'translateY(-100%)',
        opacity: '0'
      })),
      state('in', style({
        transform: 'translateY(-50%)',
        opacity: '1'
      })),
      transition('* => *', animate('300ms ease-out'))
    ])
  ]
})
export class LogInComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isLoading = false;
  windowState = 'out';
  showPass = false;
  queryParams = {};
  showResendConfirmationEmail = false;

  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  $destroy = new Subject();

  @HostListener('window:resize', ['$event'])
  windowResize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private router: Router,
    private userInfoService: UserInfoService,
    // private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private firebaseStoreService: FirebaseStoreService
  ) {
  }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn && !this.userInfoService.user) {
      this.router.navigateByUrl('/user/inventory/Main');
    }
    if (this.userInfoService.user && !this.userInfoService.user.dashboardAccess) {
      setTimeout(() => {
        this.snackBarService.alertError(this.translateService.instant('snackbar-messages.no-dashboard-access'), true);
        }, 300 );
      this.authenticationService.logOut();
    }
    this.windowState = 'in';
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      remember: [localStorage.getItem('rememberUser') === 'true']
    });

    this.route.queryParams
      .pipe(takeUntil(this.$destroy))
      .subscribe(params => {
      this.queryParams = params;
    });
  }

  private setCookie = (name,value,days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }


  resendConfirmEmail() {
    this.authenticationService.sendVerificationMail().then(() => {
      const msg = this.translateService.instant('snackbar-messages.confirm-email-sent');
      this.snackBarService.alert(msg, 'success', true);
    });
  }

  goToApp(): void {
    window.open(environment.googleAppLink, '_blank');
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.markAsDirty();
      this.form.get('email').markAsDirty();
      this.form.get('password').markAsDirty();
      this.form.updateValueAndValidity();
      return;
    }

    this.isLoading = true;

    this.setCookie('rememberUser', this.form.value.remember, this.form.value.remember ? 999 : 0);

    this.authenticationService.login(this.form.value.email, this.form.value.password)
      .then(async res => {
        this.isLoading = false;
        if (res && !res.user.emailVerified) {
          const msg = this.translateService.instant('snackbar-messages.verify-email');
          this.snackBarService.alert(msg, 'error', true);
          this.showResendConfirmationEmail = true;
          return;
        }
        const sfUser = await this.userInfoService.getUserByEmail(res.user.email);

        if (sfUser && !sfUser.dashboardAccess) {
          this.snackBarService.alertError(this.translateService.instant('snackbar-messages.no-dashboard-access'), true);
          this.authenticationService.logOut();
          return;
        }
        if (res && res.user) {
          this.windowState = 'out';
          this.router.navigate(['/user', 'inventory', 'Main']);
        }
      })
      .catch(err => {
        console.error(err);
        this.isLoading = false;
        if (err) {
          switch (err.code) {
            case 'auth/user-not-found': {
              const msg = this.translateService.instant('snackbar-messages.invalid-credentials');
              this.snackBarService.alert(msg, 'error', true);
              break;
            }
            case 'auth/wrong-password': {
              const msg = this.translateService.instant('snackbar-messages.invalid-credentials');
              this.snackBarService.alert(msg, 'error', true);
              break;
            }
            case 'auth/too-many-requests': {
              const msg = this.translateService.instant('snackbar-messages.too-many-requests');
              this.snackBarService.alert(msg, 'error', true);
              break;
            }

            default: {
              const msg = this.translateService.instant('snackbar-messages.server-error');
              this.snackBarService.alert(msg, 'error', true);
            }
          }
        }
      });
  }

  goToClosedRegistration() {
    const lang = this.translateService.currentLang || this.translateService.defaultLang;
    window.open('https://shelfit.tech' + (lang === 'en' ? '' : '/' + lang) + '?fillContactForm=true', '_blank');
  }

  ngOnDestroy() {
    this.$destroy.next();
  }

  goToRegistration(): void {
    this.router.navigate(['registration'], { queryParams: this.queryParams });
  }

  togglePassword() {
    this.showPass = !this.showPass;
  }
}
