import {Component, HostListener, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../authentication.service';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {SnackBarService} from '../../shared/services/snack-bar.service';

@Component({
  selector: 'up-pass-recover',
  templateUrl: './pass-recover.component.html',
  styleUrls: ['./pass-recover.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('out', style({
        transform: 'translateY(-100%)',
        opacity: '0'
      })),
      state('in', style({
        transform: 'translateY(-50%)',
        opacity: '1'
      })),
      transition('* => *', animate('300ms ease-out'))
    ])
  ]
})
export class PassRecoverComponent implements OnInit {

  form: FormGroup;
  isLoading = false;
  successfullySubmitted = false;
  windowState = 'out';

  screenHeight = window.innerHeight;


  @HostListener('window:resize', ['$event'])
  windowResize() {
    this.screenHeight = window.innerHeight;
  }


  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.windowState = 'in';
    this.form = this.fb.group({
      user_mail: ['', [Validators.required, Validators.email]]
    });
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.markAsDirty();
      this.form.get('user_mail').markAsDirty();
      this.form.updateValueAndValidity();
      return;
    }

    this.isLoading = true;
    this.authenticationService.recoverPassword(this.form.value.user_mail)
      .then(res => {
        this.successfullySubmitted = true;
      })
      .catch(err => {
        this.isLoading = false;
        if (err.code === 'auth/user-not-found') {
          this.snackBarService.alert('No account found with this email', 'error', true);
        } else {
          this.snackBarService.alert('Something went wrong', 'error', true);
        }
      });
  }
}
