import {Injectable} from '@angular/core';
import {UserInfoService} from './user-info.service';
import {
  ADDITIONAL_FIELDS_LIMIT,
  CUSTOM_BARCODE,
  EXPORT_PERMISSION,
  IMPORT_PERMISSION, ITEMS_LIMIT, LABELS_LIMIT,
  NOTIFICATIONS_PERMISSION,
  PHOTOS_PER_ITEM_LIMIT
} from '../shared/constants/company-plan';
import {CompanyInterface} from '../shared/interfaces/company.interface';
import { UserSnapshotService } from './UserSnapshotService';
import { UserInterface } from '../shared/interfaces/user.interface';
import { BehaviorSubject } from 'rxjs';

enum UserRoles {
  'worker',
  'admin'
}

enum CompanyPlans {
  'premium',
  'advanced',
  'free'
}

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {

  private company: CompanyInterface = {plan: {}} as CompanyInterface;
  public companyPlanId = 1;
  private userRole: 'Worker' | 'Manager' | string;
  private isLimitedUser: boolean;
  private hasDeletePermissionUser: boolean;
  private isUserExportAccess: boolean;

  onPermissionUpdate$: BehaviorSubject<UserInterface> = new BehaviorSubject(null);

  constructor(
    userInfoService: UserInfoService,
    ) {
    userInfoService.loggedUser.subscribe(user => {
      this.userRole = user?.role;
      this.onPermissionUpdates(user);
    });

    userInfoService.companyChanged.subscribe(company => {
      if (!company || !company.id) {
        this.company = {plan: {}} as CompanyInterface;
        this.companyPlanId = 1;
      } else {
        this.company = company;
        this.companyPlanId = company.plan.planName === 'Basic' ? 1 : (company.plan.planName === 'Advanced' ? 2 : 3);
      }
    });

    this.onPermissionUpdate$.subscribe(this.onPermissionUpdates);
  }

  // private onUserChange = (user: UserInterface, type: 'new' | 'modified' | 'removed') => {
  private onPermissionUpdates = (user: UserInterface) => {
    console.log('Permission updated, ', user);
    this.isLimitedUser = user?.isLimitedUser ?? false;
    this.hasDeletePermissionUser = user?.hasDeletePermission ?? true;
    this.isUserExportAccess = !!user?.exportAccess;
  }

  get isPremium() {
    return this.companyPlanId === 3;
  }

  get isFreePlan() {
    return this.companyPlanId === 1;
  }

  get isLogo() {
    return this.company.plan.isLogo;
  }

  isAdmin() {
    return this.userRole === 'Manager';
  }

  isLimited() {
    return this.isLimitedUser;
  }

  hasDeletePermission() {
    return this.hasDeletePermissionUser;
  }

  get hasExportAccessUser(): boolean {
    return this.isUserExportAccess && this.company.plan.isExport;
  }

  get itemsLimit() {
    return this.company.plan.entriesQty;
  }

  get labelsLimitCount(): number {
    return this.company.plan.labelsQty;
  }

  get hasImportAccess() {
    return this.company.plan.isExport;
  }

  get hasExportAccess() {
    return this.company.plan.isExport;
  }

  get getAdditionalFieldsLimitCount(): number {
    return this.company.plan.fieldsQty;
  }

  get photosLimitForItem() {
    return this.company.plan.imagesQty;
  }

  get hasNotificationsAccess() {
    return this.company.plan.isNotifications;
  }

  get hasBarCodeAccess() {
    return this.company.plan.isBarcode;
  }
}
