import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanyInterface } from '../../shared/interfaces/company.interface';
import { UserInterface } from '../../shared/interfaces/user.interface';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { AuthenticationService } from '../authentication.service';
import { CompanySnapshotService } from '../CompanySnapshotService';
import { FoldersTree } from '../FoldersTreeService';
import { InventorySnapshotsService } from '../InventorySnapshotsService';
import { NotificationsSnapshotService } from '../NotificationsSnapshotService';
import { PreorderedSnapshotService } from '../PreorderedSnapshotService';
import { RemovedSnapshotService } from '../RemovedSnapshotService';
import { UserInfoService } from '../user-info.service';
import { UserSnapshotService } from '../UserSnapshotService';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'up-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy, OnInit{

  loadingUserInfo = true;

  firestoreError = '';
  $destroy = new Subject();
  dataLoaded = new Subject();
  networkStatus: boolean = true;

  ngOnDestroy() {
    //console.log('LayoutComponent ngOnDestroy');
    this.destroyServices();
    this.$destroy.next();
  }

  private destroyServices() {
    this.foldersTree.destroy();
    this.userSnapshot.destroy();
    this.companySnapshot.destroy();
    this.removedSnapshot.destroy();
    this.preorderedSnapshot.destroy();
    this.notificationsSnapshot.destroy();
    this.inventorySnapshots.destroy();
  }

  constructor(
    private commonService: CommonService,
    private translateService: TranslateService,
    private companySnapshot: CompanySnapshotService,
    private userInfoService: UserInfoService,
    private foldersTree: FoldersTree,
    private userSnapshot: UserSnapshotService,
    private authService: AuthenticationService,
    private snackBarService: SnackBarService,
    private removedSnapshot: RemovedSnapshotService,
    private preorderedSnapshot: PreorderedSnapshotService,
    private notificationsSnapshot: NotificationsSnapshotService,
    private inventorySnapshots: InventorySnapshotsService
  ) {
    this.subscribeToDataLoading();
    this.userInfoService.userLoggedOut
      .pipe(takeUntil(this.$destroy))
      .subscribe(() => {
        this.loadingUserInfo = true;
        this.subscribeToDataLoading();
      });
  }

  ngOnInit() {
    this.commonService.getNetworkStatusObservable().subscribe(status => {
      if (this.networkStatus === false && status == true && this.authService.isLoggedIn) {
        this.snackBarService.alert(this.translateService.instant('other.internet-connection-restored'));
      }
      this.networkStatus = status
    })
  }

  get logoUrl() {
    return this.translateService.currentLang !== 'he'
      ? 'assets/logos/shelfit.svg'
      : 'assets/logos/shelfit-rtl.svg';
  }

  subscribeToDataLoading() {
    this.userInfoService.companyChanged
      .pipe(takeUntil(this.dataLoaded))
      .subscribe((company) => {
        if (company && company.id) {
          this.loadingUserInfo = false;
          this.dataLoaded.next();
          this.loadFoldersTree(company.id);
          this.initUserSnapshotService(company.id, this.userInfoService.user.email);
          this.initCompanySnapshotService(company.id, this.userInfoService.user.email);
        }
      });
  }

  public loadFoldersTree(companyId: number) {
    //console.log('loadFoldersTree');
    this.foldersTree.init(companyId);
  }

  private initUserSnapshotService(companyId: number, userEmail: string) {
    this.userSnapshot.init(companyId, userEmail);
    this.userSnapshot.subscribe(['foldersIDAccess', 'dashboardAccess'], this.onUserChange);
  }

  private initCompanySnapshotService(companyId: number, userEmail: string) {
    this.companySnapshot.init(companyId, userEmail);
    this.companySnapshot.subscribe(['devices'], this.onCompanyChange);
  }

  private onUserChange = (user: UserInterface, type: 'new' | 'modified' | 'removed') => {
    //console.log('onUserChange', type);
    if (type === 'modified' || type === 'removed') {
      const msg = this.translateService.instant('snackbar-messages.user-permission-changed');
      this.snackBarService.alertError(msg, true);
      this.destroyServices();
      setTimeout(_ => this.authService.logOut(), 5000);
    }
  }

  private onCompanyChange = (prev: CompanyInterface, next: CompanyInterface, type: 'new' | 'modified' | 'removed') => {
    if (type === 'modified') {
      if (next && next.devices && next.devices[this.userInfoService.user.email]
        && this.userInfoService.currentDeviceId && next.devices[this.userInfoService.user.email] !== this.userInfoService.currentDeviceId) {
        console.log('logout!');

        this.snackBarService.alertError(this.translateService.instant('other.another-device-used'));

        this.destroyServices();
        setTimeout(_ => this.authService.logOut(), 2000);
      }
    }
  }

  checkNetworkConnection() {
    if (!this.networkStatus) {
      this.snackBarService.alertError(this.translateService.instant('other.internet-connection-not-available'))
    }
  }
}
