<img src="../../../assets/images/login-background.svg" class="background">

<div class="form-wrapper">
  <form>
    <div class="header">
      <img class="logo" src="assets/logos/shelfit.svg" alt="logo">
    </div>

    <p class="title d-flex justify-content-center">
      <span [innerHTML]="'subscription-expired.dialog-message' | translate:{name: userName}">
      </span>
    </p>

  </form>
</div>
