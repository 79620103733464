import {Component, EventEmitter, HostListener, OnDestroy, OnInit, Output} from '@angular/core';
import {UserInfoService} from '../../../core/user-info.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {CompanyInterface} from '../../interfaces/company.interface';
import {ImageUploadService} from '../../services/image-upload.service';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'up-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['user-icon.component.scss'],
})
export class UserIconComponent implements OnInit, OnDestroy {

  @Output() onLoaded = new EventEmitter<any>();

  DEFAULT_LOGO = '/assets/big-logo.svg';
  companyLogo = '';
  company: CompanyInterface;
  userName;
  isLoading = true;
  showLangDropdown = false;
  showLangWidget = false;
  loadingLogo = false;
  $destroy = new Subject();

  @HostListener('document:click', ['$event.target'])
  hideLanguagesDropDown(target) {
    const btn = document.getElementById('lang-btn');
    if (btn) {
      btn.classList.remove('focused');
    }
  }

  constructor(
    private userInfoService: UserInfoService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localeService: BsLocaleService
  ) {
    if (this.userInfoService.companyLogoUrl) {
      this.companyLogo = this.userInfoService.companyLogoUrl;
    }
    if (this.userInfoService.user && this.userInfoService.user.firstName) {
      this.userName = this.userInfoService.user.firstName;
    }
  }

  ngOnInit(): void {
    if (this.router.url === '/user/settings/main-info') {
      this.showLangWidget = true;
    }

    this.router.events
      .pipe(takeUntil(this.$destroy))
      .subscribe(routerEvent => {
      if (routerEvent instanceof NavigationEnd) {
        if (routerEvent.urlAfterRedirects === '/user/settings/main-info') {
          this.showLangWidget = true;
        } else {
          this.showLangWidget = false;
          this.showLangDropdown = false;
        }
      }
    });
    this.userInfoService.loggedUser
      .pipe(takeUntil(this.$destroy))
      .subscribe((user => {
        if (user && user.firstName) {
          this.userName = user.firstName;
        }
      }));
    this.userInfoService.companyChanged
      .pipe(takeUntil(this.$destroy))
      .subscribe(async (company) => {
        if (company && company.id) {
          this.company = company;
          if (this.company.logo) {
            this.companyLogo = this.userInfoService.companyLogoUrl;
          }
        }
    });
  }

  setLanguage(lang: string, event) {
    this.translateService.use(lang);
    this.showLangDropdown = false;
    localStorage.setItem('lang', lang);
    this.userInfoService.setUserLanguage(lang || (this.translateService.defaultLang || 'en')).then(() => {
      this.localeService.use(this.translateService.currentLang || 'en');
    });
  }

  loaded() {
    this.isLoading = false;
    this.onLoaded.emit();
  }

  get flagSrc() {
    const lang = this.translateService.currentLang || this.translateService.defaultLang;
    return `assets/flags/${lang}.jpg`;
  }

  get flagString(): string {
    const lang = this.translateService.currentLang || this.translateService.defaultLang;
    return lang.toLocaleUpperCase();
  }

  toggleLangDropDown() {
    // setTimeout(() => {
    //   if (!this.showLangDropdown) {
    //     this.showLangDropdown = true;
    //   }
    // }, 4);
    //
    // if (!this.showLangDropdown) {
    //     const subscriber = fromEvent(document, 'click').pipe(
    //       take(2)
    //     ).subscribe((event) => {
    //       this.showLangDropdown = false;
    //       document.querySelector('body').click();
    //     });
    // }
  }

  focusElement(clickEvent) {
    setTimeout(() => {
      const btn = document.getElementById('lang-btn');
      btn.classList.add('focused');
    }, 0);
  }

  ngOnDestroy() {
    this.$destroy.next();
  }
}
