<!--


-->

<div class="background-fade"
     #backgroundFade
     (click)="closeWithConfirmModal()"
     [ngStyle]="{'z-index': zIndex || 'auto'}"
     [@windowTint]="windowState">
</div>

<div class="side-popover-wrapper" [ngStyle]="{'z-index': zIndex || 'auto'}">
  <div #sidePageWrapper id="sidePageWrapper" class="side-page {{windowState}}">
    <ng-content></ng-content>

    <i class="close close-btn" *ngIf="closeBtn" (click)="closeWithConfirmModal()"></i>
  </div>

  <!--  <div class="side-page" [@RTLSlideInOut]="windowState">-->
  <!--    <ng-content></ng-content>-->
  <!--    <i class="close close-btn" *ngIf="closeBtn" (click)="closeWithConfirmModal()"></i>-->
  <!--  </div>-->
</div>

<up-confirm-modal *ngIf="showConfirmModal"
                  [pr]="sidePageWidth"
                  [msg]="confirmMsg"
                  [confirmBtnText]="'buttons.discard' | translate"
                  [cancelBtnText]="'buttons.cancel' | translate"
                  (confirm)="closeModal()"
                  (cancel)="closeConfirmation()">
</up-confirm-modal>
