import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { UserInfoService } from '../user-info.service';

@Injectable({
  providedIn: 'root'
})
export class PrivacyPolicyGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private userInfoService: UserInfoService
  ) {
  }
  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.userData) {
      return false;
    }
    let user = this.userInfoService.user;
    if (user === void 0 || user === null) {
      user = await this.userInfoService.getUserByEmail(this.authService.userData.email);
    }
    if (user === void 0 || user === null) {
      this.router.navigate(['/login']);
      return false;
    }
    if (user.isPrivacyPolicyConfirmed === void 0 || !user.isPrivacyPolicyConfirmed) {
      this.router.navigate(['/privacy-policy']);
      return false;
    }
    return true;
  }
}
