import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { tap, filter } from 'rxjs/operators';
import {UserInterface} from '../../../shared/interfaces/user.interface';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserInfoService} from '../../../core/user-info.service';
import {CompanyInterface} from '../../../shared/interfaces/company.interface';
import firebase from 'firebase/app';
import {AngularFireFunctions} from '@angular/fire/functions';
import {AngularFireAuth} from '@angular/fire/auth';
import { NotificationInterface } from '../../../shared/interfaces/notification.interface';
import { IDoc, IDocType } from 'src/app/firestore/interface';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  // private usersUrl = '/company/users';
  // private loggedInUserUrl = '/company/user/is_login';
  // private additionalUsersUrl = '/company/workers';
  // private userUrl = (id) => `/company/user/${id}`;

  constructor(
    private db: AngularFirestore,
    private func: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private userInfoService: UserInfoService) {
  }

  updateUser(user: UserInterface) {
    return this.db.collection('/Users').doc(user.email).set(user, {merge: true});
  }

  getAllCompanyUsers() {
    return this.db.collection('/Users').ref
      .where('companyID', '==', this.userInfoService.company.id)
      .get()
      .then(docs => {
        return docs.docs.map(doc => doc.data()).filter((x: UserInterface) => x.userID !== this.userInfoService.user.userID);
      });
  }

  getAllUserInventory(): Promise<any> {

    return this.db.collection(`/Companies/${this.userInfoService.company.id.toString()}/Inventory`, ref =>
      ref.where('type', '==', 'Folder')
        .where('rootFolderId', '==', 'Main')
    ).get()
      .toPromise()
      .then(querySnapshot => {
        return querySnapshot.docs.map(doc =>
          doc.data()).filter((item: IDoc) => item.type === 'Folder' && item.rootFolderId === 'Main').map((filteredItem: IDoc) => filteredItem.id);
      });
    /*return this.db.collection(`/Companies/${this.userInfoService.company.id.toString()}/Inventory`).ref
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map(doc =>
          doc.data()).filter((item: IDoc) => item.type === 'Folder' && item.rootFolderId === 'Main').map((filteredItem: IDoc) => filteredItem.id);
        });*/
  }

  getAllUserLowStockInventory(): Promise<any> {
    return this.db.collection(`/Companies/${this.userInfoService.company.id.toString()}/Inventory`).ref
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map(doc =>
          doc.data()).filter((docData: IDoc) => docData.type === 'Product' && (docData.lowQuantity && (docData.lowQuantity >= docData.quantity))).map((filteredItem: IDoc) => filteredItem.id);
        });
  }


  async updateUserData(user: UserInterface) {
    const updateUserPromise = this.db.collection('/Users').doc(user.email).set(user, {merge: true});

    const accessObject: any = {};
    accessObject[user.userID] = user.foldersIDAccess;

    const updateUsersAccessPromise = this.db.collection(`/Companies/${this.userInfoService.company.id.toString()}/UsersAccess`)
      .doc('Access')
      .set(accessObject, {merge: true});

    const updateRTCountersPromise = this.db.collection(`/Companies/${this.userInfoService.company.id.toString()}/RTCounters`)
      .doc(user.userID.toString())
      .set({
        notifications: await this.getNotificationCountForFolders(user.foldersIDAccess)
      }, {merge: true});

    return Promise.all([updateUserPromise, updateUsersAccessPromise, updateRTCountersPromise]);
  }

  async createUser(user: UserInterface) {

    const userExists = await this.afAuth.fetchSignInMethodsForEmail(user.email)
      .then((signInMethods) => {
        if (signInMethods && signInMethods.length) {
          return true;
        }
        return false;
      }).catch(error => {
        return false;
      });

    if (userExists) {
      return new Promise((resolve, reject) => {
        reject('user-exists');
      });
    }

    const companyReg: any = this.db.collection('/Companies').doc(user.companyID.toString()).ref;

    return this.db.collection('/Users').doc('Counters').get().toPromise()
      .then( x => {
        const counters: any = x.data() || {};
        counters.users = counters.users || 100;
        ++counters.users;

        return this.db.collection('/Users').doc('Counters').set(counters,  {merge: true}).then(async () => {
          user.userID = counters.users;

          const actions = this.db.firestore.batch();

          actions.update(companyReg, Object.assign({}, {
            users: firebase.firestore.FieldValue.increment(1),
          }));
          const createUserRef: any = this.db.collection('/Users').doc(user.email).ref;

          actions.set(createUserRef, Object.assign(user), {merge: true});

          const accessObject: any = new Object();
          accessObject[user.userID] = user.foldersIDAccess;

          const addUsersAccessRef: any = this.db.collection(`/Companies/${this.userInfoService.company.id.toString()}/UsersAccess`).ref
            .doc('Access');

          actions.set(addUsersAccessRef,
            accessObject, {merge: true});

          const addRTCountersRef: any = this.db.collection(`/Companies/${this.userInfoService.company.id.toString()}/RTCounters`).ref
            .doc(user.userID.toString());

          actions.set(addRTCountersRef, Object.assign({
            notifications: await this.getNotificationCountForFolders(user.foldersIDAccess),
            openTasks: 0
          }), {merge: true});

          const createWorkerFunc = this.func.httpsCallable('createWorker');
          return actions.commit().then(() => {
            return  createWorkerFunc({user}).toPromise()
              .then(result => {
                return user;
              })
              .catch(error => {
                return this.clearUserData(user);
              });
          });
        });
      });
  }

  private async clearUserData(user: UserInterface) {
    const userExists = await this.afAuth.fetchSignInMethodsForEmail(user.email)
      .then((signInMethods) => {
        if (signInMethods && signInMethods.length) {
          return true;
        }
        return false;
      }).catch(error => {
        return false;
      });

    if (userExists) {
      return this.removeUser(user.email);
    } else {
      const actions = this.db.firestore.batch();

      const companyReg: any = this.db.collection('/Companies').doc(user.companyID.toString()).ref;

      actions.update(companyReg, Object.assign({
        users: firebase.firestore.FieldValue.increment(1),
      }));
      const removeUserRef: any = this.db.collection('/Users').doc(user.email).ref;

      actions.delete(removeUserRef);


      const accessObject: any = new Object();
      accessObject[user.userID] = firebase.firestore.FieldValue.delete();

      const removeUsersAccessRef: any = this.db.collection(`/Companies/${this.userInfoService.company.id.toString()}/UsersAccess`).ref
        .doc('Access');

      actions.update(removeUsersAccessRef,
        accessObject);


      const removeRTCountersRef: any = this.db.collection(`/Companies/${this.userInfoService.company.id.toString()}/RTCounters`)
        .doc(user.userID.toString()).ref;

      actions.delete(removeRTCountersRef);

      return actions.commit();
    }
  }


  private getNotificationCountForFolders(folderIDAccess: Array<string>) {
    let query = this.db.collection<NotificationInterface>(`/Companies/${this.userInfoService.company.id.toString()}/Notifications`).ref;
    //if (folderIDAccess.findIndex(x => x === '0') < 0) {
    //  query = query.where('rootFolderId', 'in', folderIDAccess);
    //}
    const hasRootAccess = folderIDAccess.indexOf('0') !== -1;
    return query.get()
      .then(snapshot => {
        let qty = 0;
        for (const doc of snapshot.docs) {
          if (!hasRootAccess) {
            const data = doc.data();
            if (data.parentFolderID === 'Main') {
              if (folderIDAccess.indexOf(data.id) === -1) {
                continue;
              }
            }
            else if (folderIDAccess.indexOf(data.rootFolderId) === -1) {
              continue;
            }
          }
          qty++;
        }
        return qty;
      })
      .catch(ex => {
        console.error('ex => ', ex);
        return 0;
      });
  }

  removeUser(email: string) {
    const removeWorkerFunc = this.func.httpsCallable('removeWorker');
    return removeWorkerFunc({email}).toPromise();
  }

}
