import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { CompanyInterface } from '../shared/interfaces/company.interface';
import { UserInterface } from '../shared/interfaces/user.interface';

type firebaseDataTypes = string | number | boolean;

@Injectable({ providedIn: 'root' })
class CompanySnapshotService {

  private _company?: CompanyInterface;
  private readonly _store: Array<{ observableFields: Array<string>; callback: (prev: CompanyInterface, next: CompanyInterface, type: 'new' | 'modified' | 'removed') => void }>;

  constructor(private db: AngularFirestore) {
    this._company = void 0;
    this._store = [];
  }

  public subscribe(observableFields: Array<string>, callback: (prev: CompanyInterface, next: CompanyInterface, type: 'new' | 'modified' | 'removed') => void) {
    this._store.push({
      observableFields,
      callback
    });
  }

  public unsubscribe(callback: (company: CompanyInterface) => void) {
    const index = this._store.findIndex(item => item.callback === callback);
    if (index !== -1) {
      this._store.splice(index, 1);
    }
  }

  private arraysAreEqual(one: Array<firebaseDataTypes>, two: Array<firebaseDataTypes>) {
    const a = one || [], b = two || [];
    if (a.length !== b.length) {
      return false;
    }
    for (const x of a) {
      if (b.indexOf(x) === -1) {
        return false;
      }
    }
    for (const y of b) {
      if (a.indexOf(y) === -1) {
        return false;
      }
    }
    return true;
  }

  private objectsAreEqual(one: { [key: string]: firebaseDataTypes }, two: { [key: string]: firebaseDataTypes }) {
    const a = one || {}, b = two || {};
    for (const x in a) {
      if (a[x] !== b[x]) {
        return false;
      }
    }
    for (const y in b) {
      if (b[y] !== a[y]) {
        return false;
      }
    }
    return true;
  }

  private detectChanges(nextCompany: CompanyInterface) {
    const store = this._store;
    for (const item of store) {
      if (nextCompany === void 0) {
        item.callback(this._company, void 0, 'removed');
      }
      else if (this._company === void 0) {
        item.callback(this._company, nextCompany, 'new');
      }
      else {
        let hasChanges = false;
        for (const field of item.observableFields) {
          if (Array.isArray(this._company[field])) {
            hasChanges = !this.arraysAreEqual(this._company[field], nextCompany[field]);
          }
          else if (typeof this._company[field] === 'object') {
            hasChanges = !this.objectsAreEqual(this._company[field], nextCompany[field]);
          }
          else {
            hasChanges = this._company[field] !== nextCompany[field];
          }
          if (hasChanges) {
            break;
          }
        }
        if (hasChanges) {
          item.callback(this._company, nextCompany, 'modified');
        }
      }
    }
  }

  private _listener: () => void;
  private _companyId: number;
  private _userEmail: string;

  public init(companyId: number, userEmail: string) {
    this._companyId = companyId;
    this._userEmail = userEmail;
    this._listener = this.db.collection<CompanyInterface>(`/Companies`).doc(this._companyId.toString()).ref.onSnapshot({ includeMetadataChanges: true }, this.onSnapshot, ex => { console.error(ex); });
  }

  public destroy() {
    if (this._listener !== void 0) {
      console.log('UserSnapshotService destroy');
      this._listener();
      this._store.length = 0;
      this._company = void 0;
    }
  }

  private onSnapshot = async (snapshot: firebase.firestore.DocumentSnapshot<CompanyInterface>) => {
    const company = snapshot.exists ? snapshot.data() : void 0;
    this.detectChanges(company);
    this._company = company;
  }
}

export { CompanySnapshotService }
