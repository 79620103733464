import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserInfoService} from '../../../core/user-info.service';
import {CompanyInterface} from '../../../shared/interfaces/company.interface';
import firebase from 'firebase/app';
import {CustomFieldInterface} from '../../../shared/interfaces/custom-field.interface';
import { ICompany, ICompanyCustomField } from '../../../firestore/interface';
import { InventoryItemInterface } from '../../../shared/interfaces/inventory-item.interface';
import { IInventoryItem } from '../../../shared/interfaces/IInventoryItem';
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { COMPANY_PLAN_TYPE } from 'src/app/shared/constants/company-plan';


@Injectable({
  providedIn: 'root'
})
export class CompanyDataService {

  company: CompanyInterface;
  plansInfo = {
    1: {
      activityHistory: 0,
      entriesQty: 150,
      fieldsQty: 2,
      imagesQty: 1,
      isBarcode: false,
      isExport: false,
      isLogo: false,
      isNotifications: false,
      isTasks: false,
      labelsQty: 2,
      planName: 'Basic',
      trashDays: 10,
      userQty: 1
    },
    2: {
      activityHistory: 10,
      entriesQty: 2500,
      fieldsQty: 10,
      imagesQty: 4,
      isBarcode: true,
      isExport: true,
      isLogo: true,
      isNotifications: true,
      isTasks: true,
      labelsQty: 10,
      planName: 'Advanced',
      trashDays: 20,
      userQty: 4
    },
    3: {
      activityHistory: 20,
      entriesQty: 100000,
      fieldsQty: 30,
      imagesQty: 6,
      isBarcode: true,
      isExport: true,
      isLogo: true,
      isNotifications: true,
      isTasks: true,
      labelsQty: 30,
      planName: 'Premium',
      trashDays: 30,
      userQty: 6
    },
  };

  constructor(private db: AngularFirestore, private userInfoService: UserInfoService) {
    this.userInfoService
      .companyChanged.subscribe(company => {
        if (company && company.id) {
          this.company = company;
        }
    });
  }

  addTag(tag: string) {
    const companyRef = this.db.collection('/Companies').doc(this.company.id.toString());

    return this.userInfoService.loadCompany()
      .then((company: CompanyInterface) => {
        company.labels = company.labels || [];
        if (company.labels.findIndex(x => x === tag) >= 0) {
          return new Promise((resolve, reject) => {
            return reject({code: 'label-exists'});
          });
        } else {
          return companyRef.update({
            labels: firebase.firestore.FieldValue.arrayUnion(tag)
          }).then( result => {
            return result;
          });
        }
      });
  }

  removeTag(tag: string) {
    const companyRef = this.db.collection('/Companies').doc(this.company.id.toString());

    // Atomically add a new region to the "regions" array field.
    return companyRef.update({
      labels: firebase.firestore.FieldValue.arrayRemove(tag)
    });
  }

  updateCustomFields(fieldToAdd: CustomFieldInterface, fieldToRemove: string) {
    return this.userInfoService.loadCompany()
      .then((company: CompanyInterface) => {
        if (company) {
          company.customFields = company.customFields || [];
        }
        if (fieldToRemove && !fieldToAdd) {
          company.customFields = company.customFields.filter(x => x.name !== fieldToRemove);
        }
        if (fieldToAdd && !fieldToRemove) {
          if (company.customFields.findIndex(x => x.name === fieldToAdd.name) >= 0) {
            return new Promise((resolve, reject) => {
              return reject({code: 'field-exists'});
            });
          }
        }
        if (fieldToAdd && fieldToRemove) {
          const indexOfRemoved = company.customFields.findIndex(x => x.name === fieldToRemove);
          if (indexOfRemoved >= 0) {
            company.customFields[indexOfRemoved] = fieldToAdd;
          }
        } else if (fieldToAdd){
          company.customFields.push(fieldToAdd);
        }

        const companyRef = this.db.collection('/Companies').doc(this.company.id.toString());

        return companyRef.set({
          customFields: company.customFields
        }, {merge: true}).then(result => {
          return this.userInfoService.updateCurrentCompanyMainInfo({customFields: company.customFields} as CompanyInterface);
        });
      });
  }

  updateCompanyPlan(planId) {
    const companyRef = this.db.collection('/Companies').doc(this.company.id.toString());
    const plan = this.plansInfo[planId];
    return companyRef.set({plan}, { merge: true}).then( result => {
      return this.userInfoService.updateCurrentCompanyMainInfo({plan} as CompanyInterface);
    });
  }

  downgradeCompanyPlan(initiatorEmail: string) {
    this.updateCompanyPlan(COMPANY_PLAN_TYPE.BASIC)
      .then(_ => {
        this.db.collection<UserInterface>('/Users').ref.where('companyID', '==', this.company.id).get()
          .then((snapshot) => {
            const actions = this.db.firestore.batch();
            snapshot.forEach(doc => {
              const userEmail = doc.data().email;
              if (userEmail == initiatorEmail) return;
              const userReg: any = this.db.collection('/Users').doc(userEmail).ref;
              actions.update(userReg, { showBasicPlanMsg: true });
            });
            actions.commit();
          });

      });
  }
}
