import {Component} from '@angular/core';
import {AuthenticationService} from '../authentication.service';

@Component({
  selector: 'up-under-dev-page',
  templateUrl: './under-dev-page.component.html',
  styleUrls: ['./under-dev-page.component.scss']
})
export class UnderDevPageComponent {
  firstLoading: any;

  constructor() {
  }
}
