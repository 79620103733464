import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FREE_USERS_LIMIT, PLAN_PRICE, USER_PRICE} from '../../../../shared/constants/company-plan';
import {UsersService} from '../../services/users.service';
import {SidePopoverScreenComponent} from '../../../../shared/components/side-popover-screen/side-popover-screen.component';
import {ActivatedRoute} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {SnackBarService} from '../../../../shared/services/snack-bar.service';
import {of} from 'rxjs';
import {UserInfoService} from '../../../../core/user-info.service';

@Component({
  selector: 'up-workers-number',
  templateUrl: './workers-number.component.html',
  styleUrls: ['./workers-number.component.scss']
})
export class WorkersNumberComponent implements OnInit {

  @Input() wrapper: SidePopoverScreenComponent;
  companyPlan = 'Basic';
  usersCount = 0;
  additionalUsersCount = 0;
  signedUpUsers = 0;
  maxUsersCount = 10;
  includedInPlanUsers = 8;
  userPrice = 0;
  currentPlanPrice = 0;
  showConfirmationScreen = false;
  showRemoveUsersScreen = false;
  maxCompanyUsers = 0;
  currencySymbol: string;

  isLoading = false;

  constructor(
    private userInfoService: UserInfoService,
    private translateService: TranslateService,
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService
) {
    this.isLoading = true;
    userInfoService.companyChanged.subscribe(company => {
      if (company && company.id) {
        this.companyPlan = company.plan.planName;
        this.includedInPlanUsers = company.plan.userQty;
        this.currentPlanPrice = PLAN_PRICE[this.currentCompanyPlanId];
        this.userPrice = USER_PRICE[this.currentCompanyPlanId];
        this.signedUpUsers = company.users;
        this.additionalUsersCount = company.additionalUsers;
        this.usersCount = company.plan.userQty + company.additionalUsers;

        this.currencySymbol = company.currencySymbol;

        this.isLoading = false;
        this.activatedRoute.queryParams.subscribe((params: {users: string}) => {
          if (params.users) {
            this.usersCount = +params.users;
            this.switchToConfirmationScreen();
          }
        });
      }
    });
  }

  ngOnInit(): void {
  }

  get currentCompanyPlanId() {
    return this.companyPlan === 'Premium' ? 3 : (this.companyPlan === 'Advanced' ? 2 : 1);
  }

  addUsers(count) {
    this.usersCount += count;

    if (this.usersCount < this.includedInPlanUsers) {
      this.usersCount = this.includedInPlanUsers;
    }
  }

  switchToConfirmationScreen() {
    if (this.usersCount < this.signedUpUsers) {
      this.showRemoveUsersScreen = true;
    } else {
      this.showConfirmationScreen = true;
      this.showRemoveUsersScreen = false;
    }
  }

  get totalPrice() {
    return this.currentPlanPrice + ((this.usersCount - this.includedInPlanUsers) * this.userPrice);
  }

  get translatedUserPlan() {
    return this.translateService.instant('other.company-plan.' + this.currentCompanyPlanId);
  }

  onWorkerRemoved() {
    this.isLoading = false;
    this.signedUpUsers--;
  }

  updateUsersCount() {
    this.isLoading = true;
    const usersCount = this.usersCount - this.includedInPlanUsers;
    this.userInfoService.addCompanyAdditionalUsers(usersCount)
      .then(result => {
          // todo remove
          console.log('AdditionalUsers');
          console.log(result);
          this.wrapper.closeModal();
      })
      .catch(error => {
          const msg = this.translateService.instant('snackbar-messages.users-count-update-problem');
          this.snackBarService.alertError(msg);
      })
      .finally(() => {
        this.isLoading = false;
      });
    // this.usersService.updateAdditionalUsersCount(usersCount).pipe(
    //   catchError(err => {

    //     return of(null);
    //   })
    // ).subscribe(res => {

    //   if (res && (!res.status || res.status === true)) {
    //   }
    // });
  }
}
