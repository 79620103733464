import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { IInventoryItem } from '../shared/interfaces/IInventoryItem';
import { INotificationSnapshotCallback } from '../shared/interfaces/INotificationSnapshotCallback';
import { ISearchPayload } from '../shared/interfaces/ISearchPayload';
import { ISort } from '../shared/interfaces/ISort';
import { FirebaseLocalImageCache } from './FirebaseLocalImageCache';

@Injectable({ providedIn: 'root' })
class RemovedSnapshotService {

  private inited: boolean;
  private readonly _store: Array<IInventoryItem>;
  private storeLoaded: boolean;

  constructor(private db: AngularFirestore, private imageCache: FirebaseLocalImageCache) {
    this.inited = false;
    this._store = [];
    this.storeLoaded = false;
  }

  private listener: () => void;
  private companyId: number;
  private foldersIDAccess: Array<string>;

  public init(companyId: number, foldersIDAccess: Array<string> = ['0']) {
    if (this.inited) {
      return;
    }
    this.inited = true;
    this.companyId = companyId;
    this.foldersIDAccess = foldersIDAccess;

    this.listener = this.db.collection<IInventoryItem>(`/Companies/${this.companyId}/Removed`).ref.where('rootFolderId', '==', 'deleted').onSnapshot({ includeMetadataChanges: true }, this.onSnapshot, ex => console.error(ex));
  }

  public destroy() {
    console.log('RemovedSnapshotService destroy');
    this.unsubscribe();
    if (this.listener !== void 0) {
      this.listener();
    }
    this.inited = false;
    this._store.length = 0;
    this.storeLoaded = false;
  }

  private onSnapshot = async (snapshot: firebase.firestore.QuerySnapshot<IInventoryItem>) => {
    const hasRootAccess = this.foldersIDAccess.indexOf('0') !== -1;
    const changes = snapshot.docChanges();
    for (const change of changes) {
      const doc = change.doc.data();
      if (!hasRootAccess) {
        if (doc.parentFolderID === 'Main') {
          if (this.foldersIDAccess.indexOf(doc.id) === -1) {
            continue;
          }
        }
        else if (this.foldersIDAccess.indexOf(doc.rootFolderId) === -1) {
          continue;
        }
      }
      const index = this._store.findIndex(item => item.id === change.doc.id);
      if (change.type === 'removed') {
        if (index !== -1) {
          this._store.splice(index, 1);
        }
      }
      else {
        if (doc.images !== void 0 && doc.images.length !== 0 && doc.images[0].length !== 0) {
          const newImageURL = this.imageCache.getOrEmpty(doc.images[0]);
          if (doc.imageURL !== newImageURL) {
            doc.imageURL = newImageURL;
          }
        }
        if (index === -1) {
          this._store.push(doc);
        }
        else {
          this._store[index] = doc;
        }
      }
    }

    if (!snapshot.metadata.fromCache) {
      this.storeLoaded = true;
    }
    if (this.callback !== void 0) {
      this.aggregate();
    }
  }

  private sort: ISort;
  private searchPayload?: ISearchPayload;
  private limit: number;
  private callback: (snapshot: INotificationSnapshotCallback) => void;

  public subscribe(callback: (snapshot: INotificationSnapshotCallback) => void, sort: ISort, limit: number, searchPayload?: ISearchPayload) {
    this.sort = sort;
    this.limit = limit;
    this.searchPayload = searchPayload;
    this.callback = callback;
    if (this.storeLoaded) {
      this.aggregate();
    }
  }

  public unsubscribe() {
    this.callback = void 0;
    this.sort = void 0;
    this.searchPayload = void 0;
    this.limit = void 0;
  }

  private filterPredicate = (doc: IInventoryItem) => {
    if (this.searchPayload !== void 0) {
      if (this.searchPayload.lowerCaseName !== void 0) {
        if (doc.lowerCaseName.indexOf(this.searchPayload.lowerCaseName) === -1) {
          return false;
        }
      }
      if (this.searchPayload.lowerCaseNotes !== void 0) {
        if (doc.lowerCaseNotes.indexOf(this.searchPayload.lowerCaseNotes) === -1) {
          return false;
        }
      }
      if (this.searchPayload.lowerCaseLocation !== void 0) {
        if (doc.lowerCaseLocation.indexOf(this.searchPayload.lowerCaseLocation) === -1) {
          return false;
        }
      }
      if (this.searchPayload.labels !== void 0) {
        for (const label of this.searchPayload.labels) {
          if (doc.labels[label] === void 0 || !doc.labels[label]) {
            return false;
          }
        }
      }
      if (this.searchPayload.fields !== void 0) {
        for (const name in this.searchPayload.fields) {
          const value = this.searchPayload.fields[name];
          if (doc.customFields[name] !== value) {
            return false;
          }
        }
      }
    }
    return true;
  }

  private aggregate() {
    const store = this._store;
    const items = store.filter(this.filterPredicate);
    this.notify(items);
  }

  private notify(items: Array<IInventoryItem>) {
    if (this.callback === void 0) {
      return;
    }
    let sorted: Array<IInventoryItem>;
    switch (this.sort.key) {
      case 'deletedAt':
        if (this.sort.order === 'asc') {
          sorted = items.sort((a, b) => a.deletedAt - b.deletedAt);
        }
        else {
          sorted = items.sort((a, b) => b.deletedAt - a.deletedAt);
        }
        break;
      case 'type':
        if (this.sort.order === 'asc') {
          sorted = items.sort((a, b) => a.type.localeCompare(b.type));
        }
        else {
          sorted = items.sort((a, b) => b.type.localeCompare(a.type));
        }
        break;
      case 'name':
        if (this.sort.order === 'asc') {
          sorted = items.sort((a, b) => a.name.localeCompare(b.name));
        }
        else {
          sorted = items.sort((a, b) => b.name.localeCompare(a.name));
        }
        break;
      case 'notes':
        if (this.sort.order === 'asc') {
          sorted = items.sort((a, b) => a.notes.localeCompare(b.notes));
        }
        else {
          sorted = items.sort((a, b) => b.notes.localeCompare(a.notes));
        }
      case 'location':
        if (this.sort.order === 'asc') {
          sorted = items.sort((a, b) => a.location.localeCompare(b.location));
        }
        else {
          sorted = items.sort((a, b) => b.location.localeCompare(a.location));
        }
        break;
      case 'quantity':
        if (this.sort.order === 'asc') {
          sorted = items.sort((a, b) => a.quantity - b.quantity);
        }
        else {
          sorted = items.sort((a, b) => b.quantity - a.quantity);
        }
      case 'price':
        if (this.sort.order === 'asc') {
          sorted = items.sort((a, b) => a.price - b.price);
        }
        else {
          sorted = items.sort((a, b) => b.price - a.price);
        }
        break;
    }
    this.callback({ size: items.length, items: sorted.slice(0, this.limit) });
  }

  /*
  private deepParent(folder: IInventoryItem): IInventoryItem | undefined {
    if (folder.parentFolderID === 'Main') {
      return folder;
    }
    const parent = this._store.find(item => item.id === folder.parentFolderID);
    if (parent === void 0) {
      return folder;
    }
    return this.deepParent(parent);
  }

  private findItem(docId: string) {
    return this._items.find(item => item.id === docId);
  }

  private aggregateTrash() {
    this._items.length = 0;
    const folders = this._store.filter(doc => doc.type === 'Folder');
    while (folders.length !== 0) {
      const folder = folders[0];
      if (!this.filter(folder)) {
        folders.splice(0, 1);
        continue;
      }
      const deepParent = this.deepParent(folder);
      const item = this.findItem(deepParent.id);
      let index = 0;
      if (item === void 0) {
        deepParent.subFoldersQty = deepParent.subProductsQty = 0;
        this._items.push(deepParent);
        index = folders.findIndex(x => x === deepParent);
      }
      else {
        if (item.id === folder.parentFolderID) {
          item.subFoldersQty++;
        }
        index = folders.findIndex(x => x === folder);
      }
      folders.splice(Math.max(index, 0), 1);
    }

    const products = this._store.filter(doc => doc.type === 'Product');
    for (const product of products) {
      if (!this.filter(product)) {
        continue;
      }
      const deepParent = this.deepParent(product);
      let item = this.findItem(deepParent.id);
      if (item === void 0) {
        this._items.push(product);
      }
      else {
        if (item.id === product.parentFolderID) {
          item.subProductsQty++;
        }
      }
    }

    this.notify();
  }
  */
}

export { RemovedSnapshotService };
